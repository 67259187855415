import React, { useEffect, useState, useRef } from "react";
import {
  BodyCopy,
  BodyCopySizeOption,
} from "~/components/elements/typography/BodyCopy";
import {
  Heading,
  HeadingSizeOption,
} from "~/components/elements/typography/Heading";
import Image from "~/components/elements/Image";
import { Section } from "~/components/elements/Section";

import { WpPage_Page_FlexibleContent_ScrollingTwoColumnBlocks } from "~/_generated/types";
import { AnimatePresence, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

export default function ScrollingTwoColumnBlocks({
  layout,
  heading,
  blocks,
  section,
}: WpPage_Page_FlexibleContent_ScrollingTwoColumnBlocks) {
  const [active, setActive] = useState(0);
  const contentRef = useRef();

  useEffect(() => {
    const images = document.querySelectorAll(".scrolling-image");

    const onScroll = (e) => {
      const scrollTop = e.target.documentElement.scrollTop;
      const images = document.querySelectorAll(".scrolling-image");
      const top = contentRef.current.getBoundingClientRect().top + 20;
      if (!images) return;
      let count = 0;
      let active = 0;
      let found = false;
      for (let image of images) {
        if (top > Math.abs(scrollTop - image.offsetTop)) {
          active = count;
          found = true;
        }
        count++;
      }
      if (found) {
        setActive(active);
      }
    };
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  return (
    <Section {...section} classname="overflow-visible">
      <div className="container">
        <div className="!max-w-[1077px] mx-auto">
          <div className="hidden grid-cols-2 md:grid gap-x-32">
            <div className="relative h-full">
              <div className="sticky space-y-16 sticky-text">
                <Heading Type="h2" size={HeadingSizeOption.h2} text={heading} />
                <div ref={contentRef} className="">
                  {blocks.map((block, i) => (
                    <div
                      key={`blockl${i}`}
                      className={`sticky-list-item ${
                        i !== blocks.length - 1 && "pb-[16px]"
                      } space-y-2 border-l  border-padding-left-large transition-opacity duration-300 ease-in-out ${
                        active === i
                          ? " border-l-purple opacity-100"
                          : "border-l-black opacity-[0.16]"
                      }`}
                    >
                      <div className="text-purple ">
                        <Heading
                          Type="h5"
                          size={HeadingSizeOption.h5}
                          text={block.heading}
                        />
                      </div>
                      <AnimatePresence>
                        {active === i && (
                          <motion.div
                            className={`prose max-w-[385px] overflow-hidden `}
                            initial="collapsed"
                            animate="open"
                            exit="collapsed"
                            variants={{
                              open: {
                                opacity: 1,
                                height: "auto",
                                transition: {
                                  opacity: { delay: 0.3, duration: 0.4 },
                                },
                              },
                              collapsed: { opacity: 0, height: 0 },
                            }}
                            transition={{ duration: 0.3 }}
                          >
                            <BodyCopy
                              text={block.content}
                              size={BodyCopySizeOption.Default}
                            />
                          </motion.div>
                        )}
                      </AnimatePresence>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div
              style={{ scrollSnapType: "y proximity" }}
              className="space-y-48 pb-[80px]"
            >
              {blocks.map((block, i) => (
                <Block
                  key={`blockr${i}`}
                  image={block.image}
                  i={i}
                  setActive={setActive}
                />
              ))}
            </div>
          </div>
          <div className="md:hidden">
            <div className="sticky space-y-16 top-40">
              <Heading Type="h2" size={HeadingSizeOption.h2} text={heading} />
              <div className="space-y-6 border-l border-l-purple border-padding-left-large">
                {blocks.map((block, i) => (
                  <div key={`blockvert${i}`} className="space-y-8">
                    <div className="rounded-[4px]">
                      <Image image={block.image} />
                    </div>
                    <div className="space-y-2">
                      <div className="text-purple ">
                        <Heading
                          Type="h5"
                          size={HeadingSizeOption.h5}
                          text={block.heading}
                        />
                      </div>
                      <div className={`prose max-w-[385px] overflow-hidden `}>
                        <BodyCopy
                          text={block.content}
                          size={BodyCopySizeOption.Default}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
}

export const Block = ({ image, i }) => {
  return (
    <div className="rounded-[4px] scrolling-image">
      <Image image={image} />
    </div>
  );
};
