import React from "react";

import {
  BodyCopy,
  BodyCopySizeOption,
} from "~/components/elements/typography/BodyCopy";
import { Heading, HeadingSizeOption } from "~/components/elements/typography/Heading";
import Image from "~/components/elements/Image";
import { Section } from "~/components/elements/Section";

import { WpPage_Page_FlexibleContent_FwTextWImage } from "~/_generated/types";
import { useInView } from "react-intersection-observer";
import LottieAnimation from "~/components/elements/LottieAnimation";

export default function FwTextWImage({
  heading,
  content,
  image,
  animation,
  mediaType,
  section,
}: WpPage_Page_FlexibleContent_FwTextWImage) {

  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 1,
  });

  return (
    <Section {...section}>
      <div className="container">
        <div className="text-center space-y-8">
          <div className="max-w-[768px] mx-auto">
            <Heading Type="h2" size={HeadingSizeOption.h1} text={heading} />
          </div>
          <div className="max-w-[650px] mx-auto">
            <BodyCopy text={content} size={BodyCopySizeOption.Large} />
          </div>
        </div>
      </div>
      {mediaType === 'animation' ? (
          <div ref={ref} className="w-full">
            {animation && (
              <LottieAnimation loop={true} play={inView} lazyData={animation} />
            )}
          </div>
        ) : (
          <>
            {image && (
              <Image image={image} />
            )}
          </>
        )}
    </Section>
  );
}
