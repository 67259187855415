import React from "react";

import { BodyCopy } from "~/components/elements/typography/BodyCopy";
import { Heading, HeadingSizeOption } from "~/components/elements/typography/Heading";
import { Section } from "~/components/elements/Section";

import { WpPage_Page_FlexibleContent_Newsletter } from "~/_generated/types";
import { Icon, IconType } from "~/components/elements/Icon";

export default function Newsletter({
  heading,
  content,
  section,
}: WpPage_Page_FlexibleContent_Newsletter) {
  return (
    <Section {...section}>
      <div className="container space-y-12">
        <div className="space-y-3.5">
          <div className="text-purple">
            <Heading text={heading} type="h2" size={HeadingSizeOption.h2} />
          </div>
          <BodyCopy text={content} />
        </div>
        {/* @TODO: Tidy this up + hook up to a service */}
        <form className="flex">
          <input type="text" className="border-t border-b border-black border-opacity-20 flex-auto placeholder-black outline-none text-[21px]" placeholder="Email Address*" />
          <button className="border-t border-b border-l  border-black border-opacity-20 py-6 px-12 group">
            <div className="transition-opacity group-hover:opacity-50 duration-300 ease-in-out">
              <Icon type={IconType.ArrowRight} />
            </div>
          </button>
        </form>
      </div>
    </Section>
  );
}
