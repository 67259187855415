import React from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import { Scrollbar, A11y, FreeMode } from "swiper";

import "swiper/css";
import "swiper/css/scrollbar";
import "swiper/css/free-mode";

import {
  BodyCopy,
  BodyCopySizeOption,
} from "~/components/elements/typography/BodyCopy";
import {
  Heading,
  HeadingSizeOption,
} from "~/components/elements/typography/Heading";
import Image from "~/components/elements/Image";
import { Section } from "~/components/elements/Section";
import { Button } from "~/components/elements/buttons/Button";
import CustomCursor from "~/components/elements/CustomCursor";

import { WpPage_Page_FlexibleContent_ImageSlider } from "~/_generated/types";

export default function ImageSlider({
  layout,
  heading,
  content,
  link,
  images,
  section,
}: WpPage_Page_FlexibleContent_ImageSlider) {
  return (
    <Section {...section}>
      <div className="container space-y-12 lg:space-y-20">
        {layout === "2" ? (
          <HeaderAlt heading={heading} content={content} />
        ) : (
          <Header heading={heading} content={content} link={link} />
        )}
        <CustomCursor
          bgColor={
            section.backgroundColour === "dark-purple"
              ? "white"
              : section.backgroundColour === "white" && "black"
          }
        >
          <Swiper
            modules={[Scrollbar, A11y, FreeMode]}
            className="relative flex flex-col w-full swiper-with-scrollbar"
            scrollbar={{ draggable: true }}
            slidesPerView={`auto`}
            freeMode={true}
            grabCursor={true}
            style={{ overflow: `visible` }}
          >
            {images.map((item, i) => (
              <SwiperSlide
                className={`max-w-[80%] sm:max-w-[50%] lg:max-w-[35%] mr-8 last:mr-0 flex flex-col justify-start`}
                key={`hpswiper${i}`}
                style={{ height: "auto" }}
              >
                <div className="text-center pointer-events-none">
                  <Image image={item.image} loading="eager" />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </CustomCursor>
      </div>
    </Section>
  );
}

export const Header = ({ heading, content, link }) => {
  return (
    <div className="grid space-y-6 lg:grid-cols-3 md:items-end lg:space-y-0">
      <div className="border-l lg:col-span-2 border-l-purple space-y-3 border-padding-left-large max-w-[520px]">
        <Heading type="h3" size={HeadingSizeOption.h3} text={heading} />
        <BodyCopy size={BodyCopySizeOption.Large} text={content} />
      </div>
      <div className="flex items-center mb-5 space-x-6 lg:justify-end">
        <span className="hidden text-xl md:block">{link.label}</span>
        <Button
          link={link.linkLink}
          icon={link.linkIcon}
          type={link.linkType}
        />
      </div>
    </div>
  );
};

export const HeaderAlt = ({ heading, content }) => {
  return (
    <div className="text-center max-w-[1010px] mx-auto">
      <Heading type="h2" size={HeadingSizeOption.h1} text={heading} />
    </div>
  );
};
