import {
  BodyCopy,
  BodyCopySizeOption,
} from "~/components/elements/typography/BodyCopy";
import {
  Heading,
  HeadingSizeOption,
} from "~/components/elements/typography/Heading";
import Image from "~/components/elements/Image";
import { Section } from "~/components/elements/Section";
import { create } from "@lottiefiles/lottie-interactivity";
import { Player } from "@lottiefiles/react-lottie-player";
import React, { useEffect, useRef, useState } from "react";

import { WpPage_Page_FlexibleContent_AnimatingTwoColumnBlocks } from "~/_generated/types";

export default function AnimatingTwoColumnBlocks({
  heading,
  content,
  blocks,
  animation1,
  layout,
  section,
}: WpPage_Page_FlexibleContent_AnimatingTwoColumnBlocks) {
  return (
    <Section {...section} classname="overflow-visible">
      {layout === "2" ? (
        <AltBlocks heading={heading} blocks={blocks} animation={animation1} />
      ) : (
        <DefaultBlocks
          heading={heading}
          content={content}
          blocks={blocks}
          animation={animation1}
        />
      )}
    </Section>
  );
}

export const DefaultBlocks = ({ heading, content, blocks, animation }) => {
  return (
    <div>
      <div className="container mb-12 md:mb-24">
        <div className="space-y-6 text-center">
          <Heading Type="h2" size={HeadingSizeOption.h2} text={heading} />
          <div className="max-w-[700px] mx-auto">
            <BodyCopy size={BodyCopySizeOption.Large} text={content} />
          </div>
        </div>
      </div>
      <div className="relative grid px-6 lg:grid-cols-2 gap-x-24 lg:px-0">
        <div className={`w-full hidden lg:block flex-auto  `}>
          <StepsAnimation animation={animation} />
        </div>
        <div className="space-y-12 lg:space-y-52 lg:mt-52 lg:mb-52">
          {blocks.map((block, i) => (
            <div className="">
              <div className="border-b lg:border-b-0 lg:border-l border-purple pb-[2rem] lg:pb-0 pl-0 lg:pl-12 max-w-[460px] mx-auto lg:ml-0 text-center lg:text-left">
                <div className="flex items-center justify-center w-8 h-8 mx-auto mb-6 text-white rounded-full bg-purple lg:mb-32 lg:mx-0">
                  0{i + 1}
                </div>
                <div className="space-y-4">
                  <div className="text-purple">
                    <Heading
                      Type="h5"
                      size={HeadingSizeOption.h5}
                      text={block.heading}
                    />
                  </div>
                  <div className="prose">
                    <BodyCopy
                      text={block.content}
                      size={BodyCopySizeOption.Large}
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export const AltBlocks = ({ heading, blocks, animation }) => {
  return (
    <div className="container">
      <div className="border-l border-l-purple border-padding-left-large">
        <div className="text-left max-w-[850px] mb-12 md:mb-32">
          <Heading Type="h2" size={HeadingSizeOption.h2} text={heading} />
        </div>
        <div className="relative grid md:grid-cols-2">
          <div className="space-y-12 md:space-y-52">
            {blocks.map((block, i) => (
              <div className=" max-w-[324px] mr-auto">
                <div className="space-y-4">
                  <div className="text-purple ">
                    <Heading
                      Type="h4"
                      size={HeadingSizeOption.h4}
                      text={block.heading}
                    />
                  </div>
                  <div className="prose purple-bullet-list">
                    <BodyCopy
                      text={block.content}
                      size={BodyCopySizeOption.Default}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className={`w-full hidden md:block flex-auto  `}>
            <StepsAnimation animation={animation} />
          </div>
        </div>
      </div>
    </div>
  );
};

export const StepsAnimation = ({ animation }) => {
  const containerRef = useRef();
  const [playerIns, setPlayerIns] = useState(null);
  const [created, setCreated] = useState(false);

  let frames = [100, 600];
  let src = "/analytics-animation.json";

  if (animation === "solutions") {
    frames = [0, 714];
    src = "/solutions-animation.json";
  }

  const createAnimation = () => {
    if (created) return;

    setCreated(true);

    return create({
      mode: "scroll",
      player: playerIns,
      container: containerRef.current,
      actions: [
        {
          visibility: [0, 1],
          type: "seek",
          frames: frames,
        },
      ],
    });
  };

  useEffect(() => {
    if (containerRef?.current && playerIns) {
      createAnimation();
    }
  }, [containerRef, playerIns]);

  return (
    <div ref={containerRef} className="relative h-full" id="steps-container">
      <div className="sticky top-40">
        <Player
          lottieRef={(instance) => setPlayerIns(instance)}
          rendererSettings={{
            filterSize: { width: "200%", height: "200%", x: "-50%", y: "-50%" },
          }}
          src={src}
        />
      </div>
    </div>
  );
};
