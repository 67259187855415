import React from "react";

import {
  BodyCopy,
  BodyCopySizeOption,
} from "~/components/elements/typography/BodyCopy";
import { Icon, IconType } from "~/components/elements/Icon";
import { AnimatePresence, motion } from "framer-motion";
import { Button } from "./buttons/Button";

export default function Note({
  noteBackgroundColour,
  noteHeading,
  noteContent,
  noteLink,
  noteIcon,
  noteType,
  active = true,
  accordion = false,
  speechBubble = false,
  flip = false,
  largeCopy = false,
  classname = "",
}) {

  let bgColour = "bg-purple-2 bg-opacity-[.08]";
  let iconColour = "text-purple-2 text-opacity-[0.8]";
  let headingColour = "text-black";
  let contentColour = "text-black";
  let padding = "px-5 md:px-[36px] py-6  md:py-8";

  switch (noteBackgroundColour) {
    case "transparent":
      bgColour = "bg-transparent";
      padding = "px-0 py-0";
      break;
    case "purple":
      bgColour = "bg-purple-2";
      iconColour = "text-purple-2";
      break;
    case "light-purple":
      bgColour = "bg-[#f3f3fc]";
      iconColour = "text-[#f3f3fc]";
      break;
    case "dark-purple":
      bgColour = "bg-dark-purple";
      iconColour = "text-dark-purple";
      break;
    case "dark-purple-2":
      bgColour = "bg-[#141933]";
      iconColour = "text-[#141933]";
      break;
    case "green":
      bgColour = "bg-[#DBFFF0]";
      iconColour = "text-[#DBFFF0]";
      break;
    case "orange":
      bgColour = "bg-orange-4";
      break;
  }

  switch (noteHeading.colour) {
    case "black":
      headingColour = "text-black";
      break;
    case "white":
      headingColour = "text-white";
      break;
    case "purple":
      headingColour = "text-purple-2";
      break;
    case "green":
      headingColour = "text-green-2";
      break;
    case "dark-green":
      headingColour = "text-dark-green-1";
      break;
    case "orange":
      headingColour = "text-orange-1";
      break;
    case "dark-orange":
      headingColour = "text-orange-1";
      break;
    case "grey":
      headingColour = "text-purple-2";
      break;
  }

  switch (noteContent.colour) {
    case "black":
      contentColour = "text-black";
      break;
    case "white":
      contentColour = "text-white";
      break;
    case "purple":
      contentColour = "text-purple-2";
      break;
    case "green":
      contentColour = "text-green-1";
      break;
    case "orange":
      contentColour = "text-orange-1";
      break;
    case "grey":
      contentColour = "!opacity-60";
      break;
  }

  return (
    <div
      className={`${bgColour} note rounded-[10px] md:max-w-[480px] relative ${classname}`}
    >
      {speechBubble && (
        <div
          className={`triangle triangle--${noteBackgroundColour} absolute left-1/2 transform -translate-x-1/2 ${
            flip ? "-bottom-8 rotate-180" : "-top-8"
          } `}
        ></div>
      )}
      <div className={`${padding}`}>
        <div className={`flex items-center justify-start ${headingColour}`}>
          <div className="flex items-start justify-start w-full space-x-2">
            {noteHeading.icon && noteHeading.icon !== "none" && (
              <div
                className={`${
                  noteHeading.icon !== "eye" && "bg-current"
                } w-5 h-5 mt-[2px] rounded-full flex items-center justify-center`}
              >
                <div
                  className={`${
                    noteHeading.icon === "eye"
                      ? "w-5 h-5 text-current"
                      : `w-3 h-3 ${iconColour}`
                  } fw-icon`}
                >
                  <Icon type={noteHeading.icon} />
                </div>
              </div>
            )}
            <div className="text-[18px] leading-[22px] ">
              {noteHeading?.text}
            </div>
          </div>
          {accordion && (
            <div
              className={`text-current ml-auto fw-icon transform transition-transform duration-200 ease-in-out ${
                active && "rotate-180"
              }`}
            >
              <div className="w-2 h-2 transform rotate-45 border-b-2 border-r-2 border-current"></div>
            </div>
          )}
        </div>
        <AnimatePresence>
          {active && (
            <motion.div
              className={`${contentColour} overflow-hidden`}
              key="content"
              initial="collapsed"
              animate="open"
              exit="collapsed"
              variants={{
                open: { opacity: 1, height: "auto" },
                collapsed: { opacity: 0, height: 0 },
              }}
              transition={{ duration: 0.3 }}
            >
              <div className="pt-[15px]">
                <BodyCopy
                  text={noteContent.text}
                  size={
                    largeCopy
                      ? BodyCopySizeOption.Large
                      : BodyCopySizeOption.Default
                  }
                />
              </div>
              {noteLink && (
                <div className="mt-3">
                  <Button link={noteLink} type={noteType} icon={noteIcon} />
                </div>
              )}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
}
