import { m, LazyMotion, domAnimation } from "framer-motion";
import React, { useState } from "react";
import { Icon, IconType } from "./Icon";

export default function Switcher({
  label1,
  label2,
  active,
  setActive,
  light = false,
  darkPurpleDot = false,
  showIcons = false,
  labelOpacity = false,
}) {
  return (
    <div
      className="flex items-center space-x-3 cursor-pointer"
      onClick={() => setActive(!active)}
    >
      <div
        className={`transition-opacity duration-300 ease-in-out ${
          labelOpacity
            ? !active
              ? "opacity-100"
              : "opacity-20 hover:opacity-60"
            : "opacity-100"
        }`}
      >
        {label1}
      </div>
      <LazyMotion features={domAnimation}>
        <m.div
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          transition={{ duration: 0.3 }}
          className={`w-12 h-6  rounded-[46px] relative ${
            light ? "bg-black" : "bg-white"
          } ${
            active && light ? "bg-green-2" : active && !light && "bg-purple"
          } `}
        >
          {showIcons && (
            <>
              <div
                className={`w-3.5 h-3.5 fw-icon absolute top-1/2 transform -translate-y-1/2 left-1.5 text-white transition-opacity duration-300 ease-in-out ${
                  light ? "opacity-100" : "opacity-0"
                }`}
              >
                <Icon type={IconType.Day} />
              </div>
              <div
                className={`w-3.5 h-3.5 fw-icon absolute top-1/2 transform -translate-y-1/2 right-1.5 text-dark-purple transition-opacity duration-300 ease-in-out ${
                  !light ? "opacity-100" : "opacity-0"
                }`}
              >
                <Icon type={IconType.Night} />
              </div>
            </>
          )}
          <div
            className={`w-4  h-4  ${
              light
                ? "bg-white"
                : darkPurpleDot
                ? "bg-dark-purple"
                : "bg-purple"
            } ${
              active && !light && "!bg-white"
            }  rounded-full absolute top-1/2 transform -translate-y-1/2 transition-all duration-300 ease-in-out ${
              active ? "translate-x-[calc(100%+11px)]" : "translate-x-1"
            }`}
          />
        </m.div>
      </LazyMotion>
      <div
        className={`transition-opacity duration-300 ease-in-out ${
          labelOpacity
            ? active
              ? "opacity-100"
              : "opacity-20 hover:opacity-60"
            : "opacity-100"
        }`}
      >
        {label2}
      </div>

      <input type="checkbox" className="hidden" value={active} />
    </div>
  );
}
