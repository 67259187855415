import { graphql, useStaticQuery } from "gatsby";

const useGlobal = () => {
  const data = useStaticQuery(graphql`
    query {
      wp {
        global {
          global {
            form {
              jobFunctions {
                job
              }
              guides {
                guide
              }
            }
          }
        }
      }
    }
  `);

  return data?.wp?.global;
};

export default useGlobal;
