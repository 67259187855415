import React from "react";

import {
  BodyCopy,
  BodyCopySizeOption,
} from "~/components/elements/typography/BodyCopy";
import {
  Heading,
  HeadingSizeOption,
} from "~/components/elements/typography/Heading";
import Image from "~/components/elements/Image";
import { Section } from "~/components/elements/Section";
import Note from "~/components/elements/Note";

import { WpPage_Page_FlexibleContent_TwoColumnTextWImage } from "~/_generated/types";
import { useInView } from "react-intersection-observer";
import LottieAnimation from "../elements/LottieAnimation";

export default function TwoColumnTextWImage({
  heading,
  content,
  image,
  note,
  animation,
  mediaType,
  section,
}: WpPage_Page_FlexibleContent_TwoColumnTextWImage) {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0,
  });

  return (
    <Section {...section}>
      <div className="container space-y-12 md:space-y-20">
        <div className="grid items-end grid-cols-1 md:grid-cols-2">
          <div className="space-y-3 ">
            <div className="max-w-[500px]">
              <Heading type="h3" size={HeadingSizeOption.h3} text={heading} />
            </div>
            <BodyCopy size={BodyCopySizeOption.Large} text={content} />
          </div>
          <div className="flex mt-6 md:justify-end md:mt-0 md:mr-12">
            <Note {...note} />
          </div>
        </div>
        {mediaType === "animation" ? (
          <div ref={ref} className="w-full">
            {animation && (
              <LottieAnimation
                loop={false}
                play={inView}
                lazyData={animation}
              />
            )}
          </div>
        ) : (
          <>{image && <Image image={image} />}</>
        )}
      </div>
    </Section>
  );
}
