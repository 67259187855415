import React, { useEffect, useLayoutEffect, useRef, useState } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import { A11y } from "swiper";

import "swiper/css";

import {
  BodyCopy,
  BodyCopySizeOption,
} from "~/components/elements/typography/BodyCopy";
import {
  Heading,
  HeadingSizeOption,
} from "~/components/elements/typography/Heading";
import Image from "~/components/elements/Image";
import { Section } from "~/components/elements/Section";

import { WpPage_Page_FlexibleContent_TwoColumnAccordion } from "~/_generated/types";
import { Icon, IconType } from "~/components/elements/Icon";
import { BgSwitcher, ColourHeading } from "./TabbedSlider";
import { motion } from "framer-motion";
import Note from "~/components/elements/Note";
import { useInView } from "react-intersection-observer";

export default function TwoColumnAccordion({
  heading,
  section,
  accordionItems,
  content,
  image,
}: WpPage_Page_FlexibleContent_TwoColumnAccordion) {
  const [isActive, setIsActive] = useState(0);
  const [swiper, setSwiper] = useState(null);

  const interval = useRef(null);

  const slideTo = (index) => swiper?.slideTo(index);

  useLayoutEffect(() => {
    slideTo(isActive ? isActive : 0);
  }, [isActive]);

  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });

  useEffect(() => {
    if (inView) {
      interval.current = setInterval(() => {
        setIsActive((i) => (i + 1) % accordionItems.length);
      }, 5000);
    }

    setIsActive(0);

    return () => {
      clearInterval(interval.current);
    };
  }, [interval, inView]);

  const overrideActive = (i) => {
    setIsActive(i);
    clearInterval(interval.current);

    interval.current = setInterval(() => {
      setIsActive((i) => (i + 1) % accordionItems.length);
    }, 5000);
  };

  return (
    <Section {...section}>
      <div className={`container`} ref={ref}>
        <div className="flex flex-wrap md:flex-nowrap items-center md:space-x-[20px]  ">
          <div className="w-full md:w-1/2">
            <div className=" md:max-w-[502px] mx-auto">
              <Swiper
                onSwiper={setSwiper}
                modules={[A11y]}
                className={`w-full items-stretch rounded-md`}
                slidesPerView={1}
                draggable={false}
              >
                {accordionItems.map((item, i) => (
                  <SwiperSlide
                    style={{ alignSelf: "stretch" }}
                    key={`tabbedswiper${i}`}
                    className="!h-[70%] md:!h-auto rounded-md"
                  >
                    <div className="h-full overflow-hidden rounded-md">
                      <Image
                        image={item?.image}
                        objectFit="cover "
                        className="max-h-[450px] md:max-h-full h-full rounded-md"
                      />
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
          <div className="w-full mt-12 md:w-1/2 md:mt-0">
            <div className="w-full md:max-w-[480px] mx-auto  ">
              <div className="max-w-[323px]">
                <Heading Type="h3" size={HeadingSizeOption.h3} text={heading} />
              </div>
              <div className="mt-[21px] max-w-[1438px]">
                <BodyCopy text={content} size={BodyCopySizeOption.Large} />
              </div>
              <div className="mt-[39px] space-y-[20px]">
                {accordionItems?.map((item, i) => (
                  <AccordionItem
                    key={`acc${i}`}
                    i={i}
                    isActive={isActive}
                    setIsActive={setIsActive}
                    overrideActive={overrideActive}
                    {...item}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
}

export const IconBgSwitcher = ({
  icon,
  backgroundColour = "white",
  active,
}) => {
  let bgColour = "bg-white";

  switch (backgroundColour) {
    case "purple":
      bgColour = "!bg-purple-2";
      break;
    case "green":
      bgColour = "!bg-green-1";
      break;
    case "orange":
      bgColour = "!bg-orange-1";
      break;
    case "white":
      bgColour = "!bg-white";
      break;
  }

  return (
    <div
      className={`${
        !active ? bgColour : "bg-white"
      } rounded-full max-w-max flex justify-between items-center p-[5px]`}
    >
      <div className="w-[10px] h-auto">
        <Icon type={icon} />
      </div>
    </div>
  );
};

export const AccordionItem = ({
  i,
  note,
  isActive,
  setIsActive,
  overrideActive,
}) => {
  return (
    <BgSwitcher className="rounded-[10px]" onClick={() => overrideActive(i)}>
      <Note {...note} active={isActive === i} accordion={true} />
    </BgSwitcher>
  );
};
