import React, { useEffect, useLayoutEffect, useRef, useState } from "react";

import {
  BodyCopy,
  BodyCopySizeOption,
} from "~/components/elements/typography/BodyCopy";
import {
  Heading,
  HeadingSizeOption,
} from "~/components/elements/typography/Heading";
import Image from "~/components/elements/Image";
import GridLines from "~/components/elements/GridLines";
import { Section } from "~/components/elements/Section";
import ButtonGroup from "~/components/elements/buttons/ButtonGroup";
import { WpPage_Page_FlexibleContent_Hero } from "src/_generated/types";
import {
  Subheading,
  SubheadingIconOption,
} from "~/components/elements/typography/Subheading";
import Border, { BorderSizeOption } from "~/components/elements/Border";
import Float from "~/components/animations/Float";
import ContactHero1 from "~/assets/images/contact-hero-1.png";
import ContactHero2 from "~/assets/images/contact-hero-2.png";
import ContactHero3 from "~/assets/images/contact-hero-3.png";
import HomeAstronaut from "~/assets/images/home-astronaut.png";
import HomeCaveman from "~/assets/images/home-caveman.png";
import HomeNewton from "~/assets/images/home-newton.png";
import CareersHero1 from "~/assets/images/careers-hero-1.png";
import CareersHero2 from "~/assets/images/careers-hero-2.png";
import CareersHero3 from "~/assets/images/careers-hero-3.png";
import CareersHero4 from "~/assets/images/careers-hero-4.png";
import { motion, useViewportScroll, useTransform } from "framer-motion";
import ScrollMove from "../animations/ScrollMove";
import { useWindowSize } from "react-use";

export default function Hero({
  subheading,
  heading,
  content,
  links,
  image,
  section,
  layout,
  whatWeDo,
  subheadingIcon,
  about,
  msf,
  contact,
}: WpPage_Page_FlexibleContent_Hero) {
  return (
    <>
      <Section {...section}>
        <div className={`overflow-hidden layout-${layout}`}>
          {layout === "what-we-do" ? (
            <WhatWeDoHero
              subheading={subheading}
              heading={heading}
              content={content}
              links={links}
              {...whatWeDo}
            />
          ) : layout === "home" ? (
            <HomeHero
              heading={heading}
              content={content}
              image={image}
              links={links}
            />
          ) : layout === "about" ? (
            <AboutHero
              subheadingIcon={subheadingIcon}
              subheading={subheading}
              heading={heading}
              image={image}
              links={links}
              about={about}
            />
          ) : layout === "careers" ? (
            <CareersHero
              subheadingIcon={subheadingIcon}
              subheading={subheading}
              heading={heading}
              image={image}
            />
          ) : layout === "msf" ? (
            <MSFHero
              subheadingIcon={subheadingIcon}
              subheading={subheading}
              heading={heading}
              image={image}
              links={links}
              {...msf}
            />
          ) : layout === "contact" ? (
            <ContactHero
              subheadingIcon={subheadingIcon}
              subheading={subheading}
              heading={heading}
              image={image}
              links={links}
              {...contact}
            />
          ) : (
            <DefaultHero
              subheadingIcon={subheadingIcon}
              subheading={subheading}
              heading={heading}
              content={content}
              image={image}
              links={links}
              section={section}
            />
          )}
        </div>
      </Section>
      {layout === "what-we-do" && (
        <div className="w-0.5 h-32 mx-auto bg-dark-purple relative z-10" />
      )}
    </>
  );
}

export const DefaultHero = ({
  subheadingIcon,
  subheading,
  heading,
  content,
  image,
  links,
  section,
}) => {
  let bgClass = "bg-[#B1FFDE]";
  let gridLinesColour = "45";

  switch (section.backgroundColour) {
    case "orange":
      bgClass = "bg-orange-2";
      gridLinesColour = "40";
      break;
    case "green":
      bgClass = "bg-[#B1FFDE]";
      break;
    case "blue":
      bgClass = "bg-blue-5";
      break;
    case "light-purple":
      bgClass = "bg-purple-3";
      break;
  }

  return (
    <>
      <GridLines colour={gridLinesColour} />
      <div className="container">
        <div className="lg:flex justify-between space-y-[50px] lg:space-y-0 lg:space-x-[70px] relative">
          <div>
            {subheading && (
              <div className="-ml-3">
                <Subheading text={subheading} icon={subheadingIcon} />
              </div>
            )}
            <div className=" mt-6 md:mt-[39px] ">
              <Border size={BorderSizeOption.Hero} colour="border-black">
                <div
                  className={`flex flex-col justify-between lg:max-w-[605px] ${
                    links ? "lg:pb-[54px]" : "lg:pb-[94px]"
                  }   `}
                >
                  <Heading
                    Type="h1"
                    size={HeadingSizeOption.h1}
                    text={heading}
                  />

                  <div className="mt-6 md:mt-[50px] lg:mt-[188px] max-w-[512px]">
                    <BodyCopy text={content} size={BodyCopySizeOption.Large} />
                  </div>
                  {links && (
                    <div className="mt-6 md:mt-[40px]">
                      <ButtonGroup
                        links={links}
                        className="!w-full md:!w-auto"
                      />
                    </div>
                  )}
                </div>
              </Border>
            </div>
          </div>

          <div>
            <div
              className={`h-full ${bgClass} w-full lg:w-[484px] lg:mr-[-50px] rounded-t-[30px] relative `}
            >
              <div className="lg:absolute top-[8rem] left-[-5rem] w-full">
                <Float>
                  <Image
                    image={image}
                    className="w-full h-full lg:max-w-[650px] default-hero-image"
                  />
                </Float>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const HomeHero = ({ heading, content, image, links }) => {
  return (
    <div className="">
      <GridLines colour="8" />
      <div className="container relative z-10 pt-4">
        <div className="max-w-[1092px] space-y-[26px] text-center mx-auto">
          <Heading Type="h1" size={HeadingSizeOption.h1Large} text={heading} />
          <div className="max-w-[770px] mx-auto">
            <BodyCopy size={BodyCopySizeOption.Large} text={content} />
          </div>
        </div>
        <div className="flex justify-center mt-[32px]">
          <ButtonGroup links={links} className="!w-full justify-center" />
        </div>
      </div>
      <HomeHeroImages heroImage={image} />
    </div>
  );
};

export const HomeHeroImages = ({ heroImage }) => {
  return (
    <div className="relative  lg:-mt-48 xl:mt-[-264px] rounded-b-[39px] md:rounded-b-[99px] overflow-hidden">
      <Image
        image={heroImage}
        className="w-full h-auto transform translate-y-[1px]  transform-gpu "
      />
      <div className="absolute top-[11vw] left-[7vw]  xxxl:left-[7%] xxxl:top-[16.5%]">
        <Float>
          <img
            src={HomeCaveman}
            className="w-[30vw] md:w-[17vw] xxxl:w-[17rem]"
          />
        </Float>
      </div>
      <div className="absolute hidden md:block top-[20vw] left-[34vw] xxxl:left-[34%] xxxl:top-[30%]">
        <Float aDelay="2">
          <img src={HomeNewton} className=" md:w-[17vw] xxxl:w-[17rem]" />
        </Float>
      </div>
      <div className="absolute top-[13vw] left-auto right-[5vw] md:right-[15vw] xxxl:right-[15%] xxxl:top-[19.5%]">
        <Float aDelay="1.5">
          <img
            src={HomeAstronaut}
            className="w-[35vw] md:w-[22vw] xxxl:w-[22rem]"
          />
        </Float>
      </div>
    </div>
  );
};

export const WhatWeDoHero = ({
  subheading,
  heading,
  content,
  image1,
  image2,
  links,
}) => {
  return (
    <>
      <GridLines colour="8" />
      <div className="container pt-8 pb-20 overflow-hidden">
        <div className="relative">
          <div className="max-w-[911px] space-y-[20px] md:space-y-[33px] text-center mx-auto">
            {subheading && (
              <div className="flex justify-center">
                <Subheading
                  text={subheading}
                  icon={SubheadingIconOption.Microchip}
                />
              </div>
            )}
            <Heading Type="h1" size={HeadingSizeOption.h1} text={heading} />
            <div className="max-w-[770px] mx-auto">
              <BodyCopy size={BodyCopySizeOption.Large} text={content} />
            </div>
          </div>
          <div className="flex justify-center mt-8 md:mt-[42px]">
            <ButtonGroup links={links} />
          </div>
          <div className="flex justify-between w-full md:-mt-16">
            <Float
              yStart={10}
              yEnd={-10}
              duration={3}
              className="w-full h-full md:max-w-[454px]"
            >
              <Image image={image1} />
            </Float>
            <Float
              yStart={10}
              yEnd={-10}
              duration={3}
              aDelay={2}
              className="w-full h-full max-w-[486px] hidden md:block"
            >
              <Image image={image2} />
            </Float>
          </div>
        </div>
      </div>
    </>
  );
};

export const AboutHero = ({
  subheadingIcon,
  subheading,
  heading,
  about,
  image,
  links,
}) => {
  return (
    <>
      <div className=" pb-[100px]">
        <GridLines colour="8" />
        <div className="container">
          <div className="grid grid-cols-8 border-l-2 md:grid-cols-12 border-l-purple">
            <div className="mt-[18px] mb-12 lg:mb-0 col-span-7 col-start-2 md:col-start-2 md:col-span-11 lg:col-span-2 lg:col-start-2">
              <Subheading text={subheading} icon={subheadingIcon} />
            </div>

            <div className="max-w-[744px]  col-span-8 col-start-2 md:col-start-2 md:col-span-12 lg:col-span-8">
              <Heading
                Type="h1"
                size={HeadingSizeOption.h1Large}
                text={heading}
              />
            </div>
          </div>
        </div>
        <div className="container grid w-full grid-cols-8 mt-12 lg:mt-20 md:grid-cols-12">
          <div className="col-span-7 col-start-2 md:col-span-11">
            <Image
              image={image}
              className=" h-full ml-auto rounded-l-xl md:rounded-l-[40px] lg:w-[120%] mr-[-20%] xxl:w-[130%] xxl:mr-[-30%] overflow-hidden transform transform-gpu"
            />
          </div>
        </div>

        <div className="container grid grid-cols-8 md:grid-cols-12 mt-12 lg:mt-[71px]">
          <div className="col-span-7 col-start-2 md:col-span-11 md:col-start-2">
            <Heading
              Type="h4"
              size={HeadingSizeOption.h4}
              text={about?.heading}
            />

            <div className="mt-[24px] md:flex md:space-x-[42px] space-y-[10px] md:space-y-0">
              <div className="max-w-[459px]">
                <BodyCopy
                  text={about?.column1}
                  size={BodyCopySizeOption.Large}
                />
              </div>
              <div className="max-w-[459px]">
                <BodyCopy
                  text={about?.column2}
                  size={BodyCopySizeOption.Large}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const CareersHero = ({ subheadingIcon, subheading, heading, image }) => {
  const { width } = useWindowSize();

  const ref = useRef();
  const { scrollY } = useViewportScroll();
  const [clientHeight, setClientHeight] = useState(0);
  const y = useTransform(
    scrollY,
    [0, clientHeight],
    [0, width < 768 ? -10 : -40]
  );
  const y2 = useTransform(
    scrollY,
    [0, clientHeight],
    [0, width < 768 ? -20 : -80]
  );
  const y3 = useTransform(
    scrollY,
    [0, clientHeight],
    [0, width < 768 ? -10 : -130]
  );
  const y4 = useTransform(
    scrollY,
    [0, clientHeight],
    [0, width < 768 ? -20 : -150]
  );

  const loaded = () => {
    setClientHeight(ref.current.clientHeight);
  };

  useLayoutEffect(() => {
    setClientHeight(ref.current.clientHeight);
  }, [scrollY]);

  return (
    <div ref={ref}>
      <GridLines colour="4" />
      <div className="relative">
        <div className="container">
          <div className="max-w-[1075px] space-y-[20px] md:space-y-[37px] text-center mx-auto">
            {subheading && (
              <div className="flex justify-center">
                <Subheading text={subheading} icon={subheadingIcon} />
              </div>
            )}
            <Heading
              Type="h1"
              size={HeadingSizeOption.h1Large}
              text={heading}
            />
          </div>
        </div>
        <div className="relative -mt-10">
          <motion.img
            style={{ y }}
            src={CareersHero1}
            loading="eager"
            onLoad={loaded}
          />
          <div className="absolute top-[11%] w-3/4 left-1/2 transform -translate-x-1/2">
            <motion.img
              style={{ y: y2 }}
              src={CareersHero2}
              loading="eager"
              onLoad={loaded}
            />
          </div>
          <div className="absolute top-[0%]">
            <motion.img
              style={{ y: y3 }}
              src={CareersHero3}
              loading="eager"
              onLoad={loaded}
            />
          </div>
          <div className="absolute top-[3.5%] z-10">
            <div className="relative">
              <motion.img
                style={{ y: y4 }}
                src={CareersHero4}
                loading="eager"
                onLoad={loaded}
              />
              <motion.div
                style={{ y: y4 }}
                className="careers-hero-layer-4-inner"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const MSFHero = ({
  subheadingIcon,
  subheading,
  heading,
  image,
  topImage,
  bottomImage,
}) => {
  return (
    <>
      <GridLines colour="35" />
      <div className="absolute top-[40px] md:top-[60px] left-0 bottom-0 w-full">
        <div className="container relative flex items-center h-full text-center ">
          <Image image={image} className="max-w-[771px] w-full mx-auto " />
          <div className="flex items-center justify-center absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-full ">
            <div className="max-w-[955px] space-y-[25px] px-2 text-center mx-auto mt-[-20px]">
              {subheading && (
                <div className="flex justify-center">
                  <Subheading text={subheading} icon={subheadingIcon} />
                </div>
              )}
              <Heading
                Type="h1"
                size={HeadingSizeOption.h1Large}
                text={heading}
              />
            </div>
          </div>

          <Float className="hidden md:block absolute top-[12rem] right-12 w-full max-w-[210px]">
            <Image image={topImage} className="w-full h-full max-w-[210px]" />
          </Float>
          <Float
            yStart={10}
            yEnd={-10}
            duration={5}
            className="hidden md:block absolute bottom-[8rem] left-6 w-full max-w-[282px]"
          >
            <Image
              image={bottomImage}
              className="w-full h-full max-w-[282px]"
            />
          </Float>
        </div>
      </div>
    </>
  );
};

export const ContactHero = ({
  subheadingIcon,
  subheading,
  heading,
  image,
  columns,
}) => {
  return (
    <>
      <div className={`${!columns && "pb-[98px]"}`}>
        <div className="container relative text-center ">
          <div className="hidden xl:block absolute top-[10vw] left-[5vw] w-16">
            <ScrollMove direction="y" offset={25}>
              <img src={ContactHero1} alt="" />
            </ScrollMove>
          </div>
          <div className="absolute right-0 hidden xl:block top-[-0.5rem] w-28">
            <ScrollMove direction="y" offset={30}>
              <img src={ContactHero2} alt="" />
            </ScrollMove>
          </div>
          <div className="hidden xl:block absolute top-[15vw] right-0 w-24">
            <ScrollMove direction="y" offset={20}>
              <img src={ContactHero3} alt="" />
            </ScrollMove>
          </div>
          <div className="max-w-[871px] space-y-[25px] text-center mx-auto relative">
            {subheading && (
              <div className="flex justify-center">
                <Subheading text={subheading} icon={subheadingIcon} />
              </div>
            )}
            <Heading Type="h1" size={HeadingSizeOption.h1} text={heading} />
          </div>
          {columns && (
            <div className="mt-[75px] lg:px-0 max-w-[910px] mx-auto text-left">
              <div className="flex ml-[-58px] flex-wrap justify-center">
                {columns?.map((col, i) => (
                  <div
                    key={`col${i}`}
                    className={`w-full md:w-1/2 pl-[58px] flex flex-col flex-auto md:max-w-[354px] ${
                      i === 1 && "text-underlines"
                    }`}
                  >
                    <HeroColumn isLast={i === columns?.length - 1} {...col} />
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export const HeroColumn = ({ content, heading, isLast }) => {
  return (
    <div
      className={`text-[18px] pt-6 md:pt-0 ${
        isLast && "border-b-0 pb-6"
      } leading-[22px] border-b md:border-l md:border-b-0 flex-auto border-purple-1   md:pl-[19px] `}
    >
      {heading}

      <div
        className="text-[16px]  leading-[1.65] tracking-[-0.01em] py-6 md:pb-[40px] contact-column"
        dangerouslySetInnerHTML={{ __html: content }}
      />
    </div>
  );
};
