import ReactMarquee from "react-fast-marquee";
import React, { useEffect } from "react";
import {
  Heading,
  HeadingSizeOption,
} from "~/components/elements/typography/Heading";
import { Section } from "~/components/elements/Section";
import { useInView } from "react-intersection-observer";

import { WpPage_Page_FlexibleContent_Marquee } from "~/_generated/types";
import { motion, useAnimation } from "framer-motion";
import { StaticImage } from "gatsby-plugin-image";
import ScrollMove from "../animations/ScrollMove";

export default function Marquee({
  lines,
  section,
  parallaxImages,
}: WpPage_Page_FlexibleContent_Marquee) {
  const controls = useAnimation();

  const { ref, inView, entry } = useInView({ threshold: 0.5 });

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  const lineVariants = {
    hidden: {
      width: "0%",
    },
    visible: {
      width: "100%",
      transition: {
        duration: 1,
        ease: "easeInOut",
      },
    },
  };

  return (
    <Section {...section}>
      <div ref={ref} className="relative">
        {/* <div className="container pb-8 md:py-8">
          <motion.div
            variants={lineVariants}
            initial="hidden"
            animate={controls}
            className=" bg-purple w-full h-[1px]"
          ></motion.div>
        </div> */}
        {/* <div className="flex flex-nowrap">
          <ReactMarquee gradient={false} speed={100}>
            {lines.map((line, i) => (
              <div
                key={`line${i}`}
                className="py-2 mx-10 whitespace-nowrap leading- text-purple"
              >
                <Heading
                  type="h1"
                  size={HeadingSizeOption.h1}
                  text={line.text}
                />
              </div>
            ))}
          </ReactMarquee>
        </div> */}
        <div className="container pt-10 md:py-10">
          {/* <div className="rotate-180">
            <motion.div
              variants={lineVariants}
              initial="hidden"
              animate={controls}
              className=" bg-purple w-full h-[1px]"
            ></motion.div>{" "}
          </div> */}
        </div>
        {parallaxImages?.topApple && (
          <div className="absolute top-[-7%] left-[15%] z-[10]">
            <ScrollMove direction="y" offset={20}>
              <StaticImage
                src={"../../assets/images/apple-1.png"}
                className="max-w-[45px] z-[10]"
              />
            </ScrollMove>
          </div>
        )}
        {parallaxImages?.kite && (
          <div className="absolute top-[-10%] right-[40%] z-[10]">
            <ScrollMove direction="x" offset={75}>
              <StaticImage
                src={"../../assets/images/kite.png"}
                className="max-w-[50px] md:max-w-[166px] z-[10]"
              />
            </ScrollMove>
          </div>
        )}
        {/* {parallaxImages?.bulb && (
          <div className="absolute bottom-[-14%] right-[22%] md:bottom-[-5%] md:right-[35%] z-[10]">
            <ScrollMove direction="y" offset={50}>
              <StaticImage
                src={"../../assets/images/bulb.png"}
                className="max-w-[50px] md:max-w-[166px] z-[10]"
              />
            </ScrollMove>
          </div>
        )} */}
        {parallaxImages?.telephone && (
          <div className="absolute top-[-17%] md:top-[-39%] left-[60%] z-[10]">
            <ScrollMove direction="x" offset={55} reverse>
              <StaticImage
                src={"../../assets/images/phone.png"}
                className="max-w-[50px] md:max-w-[140px] z-[10]"
              />
            </ScrollMove>
          </div>
        )}
        {parallaxImages?.bottomApple && (
          <div className="absolute bottom-[-15%] left-[17%] z-[10]">
            <ScrollMove direction="y" offset={20}>
              <StaticImage
                src={"../../assets/images/apple-2.png"}
                className="max-w-[60px] z-[10]"
              />
            </ScrollMove>
          </div>
        )}
      </div>
    </Section>
  );
}
