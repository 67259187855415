import React from "react";

import {
  BodyCopy,
  BodyCopySizeOption,
} from "~/components/elements/typography/BodyCopy";
import {
  Heading,
  HeadingSizeOption,
} from "~/components/elements/typography/Heading";
import Image from "~/components/elements/Image";
import { Section } from "~/components/elements/Section";
import Border, { BorderSizeOption } from "~/components/elements/Border";
import {
  WpPage_Page_FlexibleContent_Usps,
  WpPage_Page_FlexibleContent_Usps_Usps,
} from "src/_generated/types";
import { useWindowSize } from "react-use";
import { Icon, IconType } from "~/components/elements/Icon";
import LottieAnimation from "~/components/elements/LottieAnimation";
import { useInView } from "react-intersection-observer";

export default function USPs({
  heading,
  content,
  usps,
  section,
  disclaimer,
  animation,
  layout,
}: WpPage_Page_FlexibleContent_Usps) {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });

  return (
    <Section {...section}>
      <div className="container relative">
        <div
          className={
            layout === "3" ?
            `border-l border-l-purple ${
              animation ? "overflow-hidden xxl:overflow-visible md:w-[110%]" : ""
            }` : ""
          }
        >
          <div
            className={`flex ${
              layout === "2" ? "justify-center text-center " : ""
            }${layout === "3" ? "flex-col " : ""}  `}
          >
            <Border
              size={
                layout === "3"
                  ? BorderSizeOption.Large
                  : BorderSizeOption.Default
              }
              disable={layout === "2"}
              className={layout === "3" ? "!border-l-0" : ""}
            >
              <div className="space-y-[27px] mx-auto pt-1 pb-2 relative z-10">
                <div
                  className={
                    layout === "3" ? "max-w-[1103px]" : "max-w-[863px]"
                  }
                >
                  <Heading
                    Type={layout === "3" ? "h3" : "h2"}
                    size={
                      layout === "3"
                        ? HeadingSizeOption.h3
                        : HeadingSizeOption.h2
                    }
                    text={heading}
                  />
                </div>
                {content && (
                  <div className="max-w-[688px] ">
                    <BodyCopy text={content} size={BodyCopySizeOption.Large} />
                  </div>
                )}
              </div>
            </Border>
            {animation && (
              <div
                className="w-[105%] xxl:w-[115%] -mt-32 ml-[-46px] lg:ml-[-60px] xxl:ml-[-63px] relative hidden md:block"
                ref={ref}
              >
                <LottieAnimation
                  loop={true}
                  play={inView}
                  lazyData={animation}
                />
              </div>
            )}
          </div>
          <div
            className={`md:mt-12  flex flex-wrap md:-ml-5 
            ${
              layout === "2"
                ? "lg:ml-[-27px] md:mt-[70px]"
                : layout === "3"
                ? `${animation ? "md:mt-[-90px] w-[90%]" : "md:mt-[240px]"}`
                : "lg:ml-[-52px] mt-12 md:mt-[98px]"
            }`}
          >
            {usps?.map((item, i) => (
              <div
                key={i}
                className={`md:pl-5 ${
                  layout === "2" || layout === "3"
                    ? "lg:pl-[27px]"
                    : "lg:pl-[52px]"
                } w-full  md:w-1/3 mb-8 last:mb-0 md:mb-0`}
              >
                {layout === "3" ? (
                  <UspsItemAlt2 key={i} i={i} {...item} />
                ) : layout === "2" ? (
                  <UspsItemAlt key={i} i={i} {...item} />
                ) : (
                  <UspsItem key={i} {...item} />
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
      {disclaimer && <Disclaimer disclaimer={disclaimer} />}
    </Section>
  );
}

export const UspsItem = ({
  heading,
  image,
  content,
}: WpPage_Page_FlexibleContent_Usps_Usps) => (
  <div className="flex h-full">
    <Border size={BorderSizeOption.Default}>
      <div className="md:pb-[10px]">
        <Heading Type="h4" size={HeadingSizeOption.h4} text={heading} />
        <div className="mt-[25px] md:mt-[122px]">
          <Image
            className="mb-[25px] md:mb-[35px] max-w-[82px] md:max-w-[103px]"
            image={image}
          />
          <BodyCopy text={content} size={BodyCopySizeOption.Default} />
        </div>
      </div>
    </Border>
  </div>
);

export const UspsItemAlt = ({
  heading,
  image,
  content,
  i,
}: WpPage_Page_FlexibleContent_Usps_Usps) => {
  const { width } = useWindowSize();
  return (
    <div className="flex justify-center h-full text-center items-bottom">
      <Border
        size={BorderSizeOption.Default}
        disable={(width < 769 && true) || i === 0}
      >
        {i !== 0 && (
          <div className="w-full bg-purple-2 h-[1px] block md:hidden"></div>
        )}
        <div className="pt-12 md:pt-20">
          <Image
            className="mb-[25px] md:mb-[35px] max-w-[82px] md:max-w-[133px] mx-auto 
      "
            image={image}
          />
        </div>
        <div className="md:pb-[10px] max-w-[342px] mx-auto">
          <Heading Type="h5" size={HeadingSizeOption.h5} text={heading} />
          <div className="mt-[30px]">
            <BodyCopy text={content} size={BodyCopySizeOption.Default} />
          </div>
        </div>
      </Border>
    </div>
  );
};

export const UspsItemAlt2 = ({
  heading,
  image,
  content,
  i,
}: WpPage_Page_FlexibleContent_Usps_Usps) => {
  const { width } = useWindowSize();
  return (
    <div className="flex h-full items-bottom usps-alt-item-2">
      <Border
        size={BorderSizeOption.Large}
        className={((width < 769 && true) || i === 0) && "!border-l-0"}
      >
        {i !== 0 && (
          <div className="w-full bg-purple-2 h-[1px] block md:hidden"></div>
        )}
        <div className="mt-8 md:mt-0 usps-image">
          <Image
            className="mb-[25px] md:mb-[35px] max-w-[82px] md:max-w-[105px]"
            image={image}
          />
        </div>
        <div className="md:pb-2.5">
          <Heading Type="h4" size={HeadingSizeOption.h4} text={heading} />
          <div className="mt-[30px]">
            <BodyCopy text={content} size={BodyCopySizeOption.Default} />
          </div>
        </div>
      </Border>
    </div>
  );
};

export const Disclaimer = ({ disclaimer }) => (
  <div className="mt-[48px] md:mt-[96px] flex justify-center items-center px-[24px] md:px-[0]">
    <div className="flex-auto  bg-black bg-opacity-[0.15] h-[1px]"></div>
    <div className="bg-[#EEEEEE] rounded-lg max-w-[940px] border border-black border-opacity-[0.15]">
      <div className="flex py-[12px] px-[18px] items-center">
        <div className="disclaimer flex items-start text-[14px] md:text-left leading-[1.65] tracking-[-0.01em]">
          <div className="w-4.5 h-4.5 mt-0.5 text-purple mr-2 fw-icon flex-shrink-0">
            <Icon type={IconType.Warning} />
          </div>
          <div className="" dangerouslySetInnerHTML={{ __html: disclaimer }} />
        </div>
      </div>
    </div>
    <div className="flex-auto  bg-black bg-opacity-[0.15] h-[1px]"></div>
  </div>
);
