import React, { useEffect, useRef, useState } from "react";

import {
  Heading,
  HeadingSizeOption,
} from "~/components/elements/typography/Heading";
import { Section } from "~/components/elements/Section";

import { WpPage_Page_FlexibleContent_PaginationSlider } from "~/_generated/types";
import Border, { BorderSizeOption } from "~/components/elements/Border";
import { Icon, IconType } from "~/components/elements/Icon";

import Switcher from "~/components/elements/Switcher";
import { AnimatePresence, motion } from "framer-motion";

export default function PaginationSlider({
  heading,
  slides,
  slides2,
  switcher,
  section,
  layout,
}: WpPage_Page_FlexibleContent_PaginationSlider) {
  const [active, setActive] = useState(false);

  let activeSlides = slides;

  if (layout === "1") {
    if (active && active === true) {
      activeSlides = slides2;
    } else {
      activeSlides = slides;
    }
  }

  const interval = useRef(null);

  useEffect(() => {
    if(layout !== "1") return null;
    interval.current = setInterval(() => {
      setActive(!active);
    }, 15000);

    return () => {
      clearInterval(interval.current);
    };
  }, [interval, active]);

  const overrideActive = () => {
    if(layout !== "1") return null;
    setActive(!active);
    clearInterval(interval.current);

    interval.current = setInterval(() => {
      setActive(!active);
    }, 15000);
  };

  return (
    <Section {...section}>
      <div
        className={`container ${
          section?.borderTop === "curved" && "pt-12 md:pt-0"
        }`}
      >
        <div className="flex flex-col justify-between w-full space-y-6 md:space-y-0 md:flex-row">
          <div className="flex flex-col">
            {layout === "1" && (
              <div className="text-h2 !text-black flex items-end">
                <Switcher
                  label1={switcher.switch1}
                  label2={switcher.switch2}
                  light={true}
                  active={active}
                  setActive={setActive}
                  labelOpacity
                />
              </div>
            )}
            <div
              className={`${
                layout === "1"
                  ? "text-purple"
                  : "text-black border-l border-l-purple border-padding-left-small pt-2 pb-4"
              }`}
            >
              <Heading Type="h2" size={HeadingSizeOption.h2} text={heading} />
            </div>
          </div>
        </div>
        {layout === "1" && (
          <>
            {active && <AnimatedLine />}
            {!active && <AnimatedLine />}
          </>
        )}
        <div className="mt-12 md:mt-[60px] flex flex-wrap gap-y-6 md:gap-y-[50px]  ">
          <AnimatePresence>
            {activeSlides.map((slide, i) => (
              <motion.div
                initial={{ opacity: 0, y: 30 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: 0.5 + (i * 0.2) }}
                className={`w-full md:w-1/2 lg:w-1/3`}
                key={`pageswiper-${active ? "yes" : "no"}-${i}`}
                style={{ height: "auto" }}
              >
                <PaginationSlide {...slide} layout={layout} i={i} />
              </motion.div>
            ))}
          </AnimatePresence>
        </div>
      </div>
    </Section>
  );
}

const AnimatedLine = () => (
  <div className="bg-[#eee]">
    <motion.div
      initial={{ width: "0%" }}
      animate={{ width: "100%" }}
      transition={{ duration: 15 }}
      className={`mt-12 md:mt-[60px] h-[2px] bg-purple-2`}
    ></motion.div>
  </div>
)

export const PaginationSlide = ({ heading, timeLabel, i, layout, content }) => {
  let altLayout = layout === "2";

  return (
    <div className="flex flex-col flex-auto h-full">
      <Border
        size={BorderSizeOption.Default}
        colour={`border-purple-2 flex flex-col flex-auto h-full ${
          altLayout && "pr-5"
        } `}
      >
        <div className="flex flex-col h-full">
          <div className={`text-purple-2 `}>
            <Heading
              text={`0${i + 1}.`}
              size={HeadingSizeOption.h5}
              Type="h5"
            />
          </div>
          <div
            className={`mt-6 md:mt-[30px]
             flex flex-col justify-between flex-auto`}
          >
            <div className={`${altLayout ? "max-w-[350px]" : "max-w-[300px]"}`}>
              {altLayout ? (
                <h4 className="text-[21px] font-heading text-purple-2 leading-[24px] tracking-[-0.03em] ">
                  {heading}
                </h4>
              ) : (
                <Heading Type="h4" size={HeadingSizeOption.h5} text={heading} />
              )}

              {content && (
                <div className="font-heading text-[18px] tracking-[-0.03em] leading-[28px] mt-[33px] ">
                  {content}
                </div>
              )}
            </div>
            {timeLabel && (
              <div className="mt-4 md:mt-[35px]">
                <TimeLabel timeLabel={timeLabel} />
              </div>
            )}
          </div>
        </div>
      </Border>
    </div>
  );
};

export const TimeLabel = ({ timeLabel }) => (
  <div className=" bg-green-2 bg-opacity-[0.47] rounded-[4px] max-w-max">
    <div className="px-[8px] flex items-center py-1 space-x-1">
      <Icon type={IconType.ClockAlt} />
      <span className="ml-[3px] leading-4">{timeLabel}</span>
    </div>
  </div>
);
