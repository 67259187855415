import { Link } from "gatsby";

import React from "react";

import {
  Heading,
  HeadingSizeOption,
} from "~/components/elements/typography/Heading";
import Image from "~/components/elements/Image";
import { Section } from "~/components/elements/Section";
import { Button, ButtonType } from "~/components/elements/buttons/Button";

import { Icon, IconType } from "~/components/elements/Icon";

export default function Resources({
  name,
  uri,
  posts,
  category,
  description,
  hideButton = false,
  siteSearchIndex,
  featured = false,
  last = false,
  noLimit = false
}) {
  const length = posts?.nodes?.length || posts?.length;

  const link = {
    title: "View all " + name,
    url: uri,
  };

  if(length === undefined || length === 0) return null;

  return (
    <Section
      backgroundColour={
        (category.layout === "articles" || category.layout === "video") &&
        `dark-purple`
      }
      classname={`${
        (category.layout === "articles" || category.layout === "video") &&
        `max-w-none`
      } ${last && "!pb-0"} `}
    >
      <div className="container space-y-20 xl:-mb-16">
        <div className="space-y-12">
          <div className="flex flex-col pb-12 space-y-4 border-b md:flex-row md:items-end border-b-purple">
            <Heading
              Type="h2"
              size={HeadingSizeOption.h2}
              text={category.sectionHeading}
            />
            <div className="md:ml-auto text-[26px]">
              <div className="inline-block font-semibold">{length}</div> {name}
            </div>
          </div>
          {category.layout === "articles" ? (
            <Articles
              posts={posts.nodes || posts}
              name={name}
              featured={featured}
              noLimit={noLimit}
            />
          ) : category.layout === "video" ? (
            <Videos posts={posts.nodes || posts} name={name} />
          ) : (
            category.layout === "whitepapers-podcasts" && (
              <WhitepapersPodcasts posts={posts.nodes || posts} name={name} />
            )
          )}
        </div>
        {!hideButton && (
          <div className="flex items-center justify-center">
            <div className="hidden h-px md:block md:w-1/3 bg-purple-2"></div>
            <div className="flex justify-center w-aut md:w-1/3">
              <Button
                type={ButtonType.Button}
                link={link}
                icon={IconType.ArrowRightDown}
              />
            </div>
            <div className="hidden h-px md:block md:w-1/3 bg-purple-2"></div>
          </div>
        )}
      </div>
    </Section>
  );
}

export const Articles = ({ featured = false, posts, name, noLimit }) => {
  let featuredPost = null;
  let secondPost = posts[0];
  let thirdPost = posts[1];
  let sliceStart = 0;
  let sliceEnd = 3;

  if (featured) {
    featuredPost = posts[0];
    sliceStart = 1;
    sliceEnd = noLimit ? 100 : 10;
  }

  return (
    <>
      {featured && featuredPost && (
        <Link
          to={featuredPost.uri}
          className="grid space-y-12 lg:space-y-0 lg:grid-cols-2 group"
        >
          <div className="">
            <div className="overflow-hidden aspect-w-4 aspect-h-3 lg:aspect-h-2 lg:aspect-w-3">
              <Image
                className="w-full transform transition-transform duration-700 ease-in-out group-hover:scale-110 !absolute"
                image={featuredPost.article.image}
                objectFit="cover"
              />
            </div>
          </div>
          <div className="flex flex-col transition-opacity duration-300 ease-in-out lg:pl-24 lg:pr-16 group-hover:opacity-60">
            <div className="space-y-3.5 ">
              <div className="text-sm capitalize text-purple">
                {featuredPost.article.type}
              </div>
              <div className="text-[32px] leading-[1.05]">
                {featuredPost.title}
              </div>
            </div>
            <div className="mt-6 lg:mt-auto">
              <Button
                link={{ url: featuredPost.uri, title: "Read Article" }}
                icon={IconType.ArrowRightDown}
                type={ButtonType.Text}
              />
            </div>
          </div>
        </Link>
      )}
      <div className="border-t border-t-purple pt-12 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-y-12 gap-x-6 mt-12">
        {posts.slice(sliceStart, sliceEnd).map((post, i) => (
          <Link
            key={`article${i}`}
            to={post.uri}
            className="space-y-5 md:space-y-8 group"
          >
            <div className="overflow-hidden aspect-w-4 aspect-h-3">
              <Image
                image={post?.article?.image}
                className="transform transition-transform duration-700 ease-in-out group-hover:scale-110 !absolute"
                objectFit="cover"
              />
            </div>
            <div className="space-y-1 md:space-y-3.5 group-hover:opacity-60 transition-opacity duration-300 ease-in-out">
              <div className="text-sm capitalize text-purple">
                {post?.article?.type}
              </div>
              <div className="text-[21px] leading-[1.25]">
                {post.title}{" "}
                <div className="inline-block">
                  <Icon type="arrowRightDown" />
                </div>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </>
  );
};

export const WhitepapersPodcasts = ({ posts, name }) => (
  <div className="grid grid-cols-2 gap-6 lg:grid-cols-4">
    {posts.slice(0, 4).map((post, i) => (
      <Link to={post.uri} className="space-y-8 group">
        <div className="overflow-hidden aspect-w-4 aspect-h-3">
          <Image
            image={post?.article?.image}
            className="transform transition-transform duration-700 ease-in-out group-hover:scale-110 !absolute"
            objectFit="cover"
          />
        </div>
        <div className="space-y-3.5 transition-opacity duration-300 ease-in-out group-hover:opacity-50">
          <div className="text-sm capitalize text-purple">
            {post?.article?.type}
          </div>
          <div className="text-[16px] emd:text-[21px] leading-[1.25]">
            {post.title}{" "}
            <div className="inline-block">
              <Icon type="arrowRightDown" />
            </div>
          </div>
        </div>
      </Link>
    ))}
  </div>
);

export const Videos = ({ posts, name }) => (
  <div>
    {posts.slice(0, 3).map((post, i) => (
      <Link to={post.uri} className="grid md:grid-cols-2 group">
        <div className="">
          <div className="overflow-hidden aspect-h-2 aspect-w-3">
            <Image
              className="!absolute w-full transform transition-transform duration-700 ease-in-out group-hover:scale-110"
              image={post.article.image}
              objectFit="cover"
            />
          </div>
        </div>
        <div className="mt-6 space-y-6 md:mt-12 md:space-y-12 lg:mt-0 lg:pl-24 lg:pr-16">
          <div className="space-y-3.5 max-w-[420px] group-hover:opacity-60 transition-opacity duration-300 ease-in-out">
            <div className="text-sm capitalize text-purple">
              {post?.article?.type}
            </div>
            <div className="text-[32px] leading-[1.05]">
              {post.title}{" "}
              <div className="inline-block">
                <Icon type="arrowRightDown" />
              </div>
            </div>
          </div>
          {post?.article?.readwatchlistenTime && (
            <div className="flex items-center">
              <div className="w-4 h-4 fw-icon ">
                <Icon type={IconType.Clock} />
              </div>
              <span className="ml-2.5">{post.article.readwatchlistenTime}</span>
            </div>
          )}
        </div>
      </Link>
    ))}
  </div>
);
