import React, { useEffect, useState } from "react";

import {
  BodyCopy,
  BodyCopySizeOption,
} from "~/components/elements/typography/BodyCopy";
import {
  Heading,
  HeadingSizeOption,
} from "~/components/elements/typography/Heading";
import Image from "~/components/elements/Image";
import { Section } from "~/components/elements/Section";
import {
  WpPage_Page_FlexibleContent_Careers,
  WpPage_Page_FlexibleContent_Careers_Cta,
} from "src/_generated/types";
import { Button, ButtonType } from "~/components/elements/buttons/Button";
import { Icon, IconType } from "~/components/elements/Icon";
import { AnimatePresence, motion } from "framer-motion";
import getJobs from "../utils/getJobs";

export default function Careers({
  heading,
  content,
  section,
  cta,
}: WpPage_Page_FlexibleContent_Careers) {
  const [jobList, setJobList] = useState([]);

  useEffect(async () => {
    const jobs = await getJobs();
    setJobList(jobs);
  }, []);

  return (
    <Section {...section}>
      <div className="container">
        <div className="space-y-7 max-w-[730px] mx-auto text-center">
          <Heading Type="h2" size={HeadingSizeOption.h2} text={heading} />
          {content && <BodyCopy text={content} />}
        </div>
        <div className="my-20 space-y-[27px] ">
          {jobList.map((job, i) => (
            <JobListings key={`jobGroup${i}`} {...job} />
          ))}
        </div>

        <Cta {...cta} />
      </div>
    </Section>
  );
}

export const Cta = ({
  heading,
  content,
  image,
  link,
}: WpPage_Page_FlexibleContent_Careers_Cta) => (
  <div className="bg-dark-purple rounded-[10px] px-6 md:px-12 pt-12 lg:pt-0 lg:px-0 flex flex-col items-start space-y-8 lg:space-y-0 lg:flex-row lg:items-center lg:justify-between">
    <div className="lg:py-12 lg:pl-[75px] w-full lg:w-[520px]">
      <div className="pl-8 space-y-2 border-l border-l-purple-2">
        <Heading Type="h4" size={HeadingSizeOption.h4} text={heading} />
        {content && <BodyCopy text={content} size={BodyCopySizeOption.Large} />}
      </div>
    </div>
    <div className="w-[244px] ml-auto lg:ml-0 lg:!mt-auto order-10 lg:order-[0]">
      <Image image={image} />
    </div>
    <div className="lg:pr-12">
      <Button
        type={link?.linkType}
        link={link?.linkLink}
        icon={link?.linkIcon}
      />
    </div>
  </div>
);

export const JobListings = ({ department, jobs }) => {
  const [showJobs, setShowJobs] = useState(false);
  return (
    <div>
      <div
        onClick={() => setShowJobs(!showJobs)}
        className="flex items-center justify-between w-full cursor-pointer group"
      >
        <h4 className="text-purple-2 !text-[24px] md:!text-[30px] text-h4 ">
          {department}
        </h4>
        <div
          className={` transition-all duration-200 ease-in-out group-hover:opacity-20 ${
            showJobs ? "rotate-45" : "rotate-0"
          }`}
        >
          <Icon type={IconType.Faq} />
        </div>
      </div>
      <AnimatePresence>
        {!showJobs && (
          <div className="mt-[30px] bg-purple-2 w-full h-[1px]"></div>
        )}
        {showJobs &&
          jobs?.map((job, i) => <JobListing key={`job${i}`} {...job} i={i} />)}
      </AnimatePresence>
    </div>
  );
};

export const JobListing = ({ title, location, contract, link, i }) => {
  const [onHover, setOnHover] = useState(false);

  return (
    <motion.div
      className={`${i === 0 && "md:mt-12"}`}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.3 }}
      onMouseEnter={() => setOnHover(true)}
      onMouseLeave={() => setOnHover(false)}
    >
      <a
        href={link}
        target="_blank"
        className="block md:flex justify-between w-full py-4 md:py-[30px] md:p-[30px] group bg-white transition-colors duration-200 ease-in-out hover:bg-[#F9F9FF] items-center border-b border-dark-purple border-opacity-20 "
      >
        <div>
          <h5 className="text-h5 leading-[0.95]">{title}</h5>
          <div className="flex space-x-[11px] text-[16px] tracking-[-0.02em] leading-[1.563] transition-opacity duration-200 opacity-60 group-hover:opacity-100 mt-[10px]">
            <div className="flex items-start space-x-[9px]">
              <div className="mt-[5px]">
                <Icon type={IconType.Location} />
              </div>
              <span>{location}</span>{" "}
            </div>
            {/* <div className="flex items-start space-x-[9px]">
              <div className="h-[10px] w-[10px] mt-[6px]">
                <Icon type={IconType.Clock} />
              </div>
              <span>{contract}</span>
            </div> */}
          </div>
        </div>
        <div
          className={`mt-6 md:mt-0 transition-opacity duration-200 ease-in-out `}
        >
          <div className="pointer-events-none">
            <Button
              className="group-hover:bg-black"
              type={ButtonType.Button}
              icon={IconType.ArrowRightDown}
              link={{ title: "Apply Now", url: "#" }}
            />
          </div>
        </div>
      </a>
    </motion.div>
  );
};
