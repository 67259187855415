import React from "react";

function GridLines({colour = '8'}) {

  let colourClass = "grid-lines--8"

  switch (colour) {
    case "10":
      colourClass = "grid-lines--10"
      break;
    case "35":
      colourClass = "grid-lines--35"
      break;
    case "40":
      colourClass = "grid-lines--40"
      break;
    case "45":
      colourClass = "grid-lines--45"
      break;
    case "85":
      colourClass = "grid-lines--85"
      break;
    case "90":
      colourClass = "grid-lines--90"
      break;
  }

  return (
    <div className="absolute inset-0 pointer-events-none">
      <div className="container w-full h-full overflow-hidden">
        <div className="relative w-full h-full">
          <div className={`absolute inset-0 -top-full grid-lines ${colourClass}`} />
        </div>
      </div>
    </div>
  );
}

export default GridLines;
