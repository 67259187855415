import React, { useEffect, useState } from 'react';
import Lottie from 'react-lottie-player';
// import { useInView } from 'react-intersection-observer';

const LottieAnimation = ({ className = '', data, lazyData, play = true, ...props }) => {
  const [animationData, setAnimationData] = useState(data || null);

  // const [ref, inView] = useInView({
  //   triggerOnce: true,
  // });

  useEffect(() => {
    if (lazyData?.length && !animationData) {
      import(`~/assets/animations/${lazyData}.json`).then(setAnimationData);
    }
  }, []);

  return (
    <div className={`pointer-events-none w-full h-full ${className}`}>
      {animationData && <Lottie height={'100%'} width={'100%'} animationData={animationData} play={play} {...props} />}
    </div>
  );
};

export default LottieAnimation;
