import React, { useState } from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";

import {
  Heading,
  HeadingSizeOption,
} from "~/components/elements/typography/Heading";
import { Section } from "~/components/elements/Section";

import { WpPage_Page_FlexibleContent_ContactForm } from "~/_generated/types";
import InputField from "~/components/elements/forms/InputField";
import TextareaField from "~/components/elements/forms/TextareaField";
import { Icon, IconType } from "~/components/elements/Icon";
import { countries } from "../elements/forms/Countries";
import useGlobal from "~/hooks/useGlobal";

const validationSchema = Yup.object({
  firstname: Yup.string().required("Required"),
  lastname: Yup.string().required("Required"),
  email: Yup.string().email("Invalid email address").required("Required"),
  company: Yup.string(),
  jobFunction: Yup.string().required("Required"),
  telephone: Yup.string().required("Required"),
  country: Yup.string().required("Required"),
  message: Yup.string(),
});

export default function ContactForm({
  section,
  heading,
}: WpPage_Page_FlexibleContent_ContactForm) {
  const [submitted, setIsSubmitted] = useState(false);
  return (
    <Section {...section}>
      <div className="container ">
        <div className="mx-auto text-center">
          <Heading
            Type="h3"
            size={HeadingSizeOption.h3}
            text={submitted ? "Thank you for getting in touch! " : heading}
          />

          <div className="mt-[60px] max-w-[588px] mx-auto">
            {!submitted ? (
              <ContForm setIsSubmitted={setIsSubmitted} />
            ) : (
              <div>
                <div>Someone will be in contact shortly</div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Section>
  );
}

export const ContForm = ({ setIsSubmitted }) => {
  const [errorMessage, setErrorMessage] = useState("");

  const { global } = useGlobal();

  return (
    <Formik
      initialValues={{
        firstname: "",
        lastname: "",
        email: "",
        company: "",
        jobFunction: "",
        telephone: "",
        country: "",
        message: "",
      }}
      validationSchema={validationSchema}
      onSubmit={async (values) => {
        const formData = new FormData();

        for (var key in values) {
          formData.append(key, values[key]);
        }

        fetch("https://getform.io/f/dc86f3e7-bb34-4c5b-9245-68bc2af35fba", {
          method: "POST",
          body: formData,
        })
          .then((response) => {
            if (response.status === 200) {
              setIsSubmitted(true);
            }
          })
          .catch((error) => {
            setErrorMessage(error);
          });
        setErrorMessage(""); // reset error message
      }}
    >
      {(props) => (
        <Form className=" mt-9">
          <div className="mb-4 -ml-6 md:flex">
            <div className="w-full pl-6 mb-4 md:mb-0">
              <InputField name="firstname" label="First Name*" />
            </div>
            <div className="w-full pl-6">
              <InputField name="lastname" label="Last Name*" />
            </div>
          </div>

          <div className="mb-4">
            <InputField name="email" type="email" label="Email*" />
          </div>

          <div className="mb-4">
            <InputField name="company" label="Company Name" />
          </div>

          <div className="mb-4 -ml-6 md:flex">
            <div className="relative w-full pl-6">
              <label className="flex flex-col items-start flex-auto text-black font-medium text-[16px] leading-[1.65]  ">
                <div className="mb-[7px]">Job function*</div>
                <Field
                  className={`border border-gray-300 rounded-md text-black w-full  px-4 py-2  hide-dropdown relative`}
                  as="select"
                  name="jobFunction"
                >
                  <option className="opacity-[0.37]">Please select...</option>
                  {global?.form?.jobFunctions?.map((job) => (
                    <option key={job?.job} value={job?.job}>
                      {job?.job}
                    </option>
                  ))}
                </Field>
              </label>
              <div className="absolute right-6 top-[66%]">
                <Icon type={IconType.DropDown} />
              </div>
            </div>

            <div className="relative w-full pl-6">
              <label className="flex flex-col items-start flex-auto text-black font-medium text-[16px] leading-[1.65]  ">
                <div className="mb-[7px]">Country*</div>
                <Field
                  className={`border border-gray-300 rounded-md text-black w-full  px-4 py-2  hide-dropdown relative`}
                  as="select"
                  name="country"
                >
                  <option className="opacity-[0.37]">Please select...</option>
                  {countries?.map((country) => (
                    <option key={country} value={country}>
                      {country}
                    </option>
                  ))}
                </Field>
              </label>
              <div className="absolute right-6 top-[66%]">
                <Icon type={IconType.DropDown} />
              </div>
            </div>
          </div>
          <div className="mb-4">
            <InputField name="telephone" label="Phone number*" />
          </div>
          <div className="mb-4">
            <TextareaField name="message" label="Your message" />
          </div>

          <div className="flex justify-start mt-11">
            <button
              type="submit"
              className="bg-purple-2 rounded-[4px] solid-btn py-[20px] px-[34px] lg:px-[49px] hover:bg-dark-purple"
            >
              <div className="flex items-center justify-center w-full">
                <span className="font-normal">Send Enquiry</span>
                <div className="inline-block ml-[16px]">
                  <Icon type={IconType.ArrowRightDown} />
                </div>
              </div>
            </button>
          </div>
          {errorMessage?.length > 0 && (
            <div
              className="mt-4 text-red"
              dangerouslySetInnerHTML={{ __html: errorMessage }}
            />
          )}
        </Form>
      )}
    </Formik>
  );
};
