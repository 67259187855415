import React, { useEffect, useState } from "react";
import { Section } from "~/components/elements/Section";
import {
  WpPost_Article_FlexibleContent_Video,
} from "src/_generated/types";
import Image from "~/components/elements/Image";
import { Icon, IconType } from "~/components/elements/Icon";
import ModalVideo from "react-modal-video"
import "react-modal-video/scss/modal-video.scss"

export default function Video({
  image,
  video,
  caption,
  section,
}: WpPost_Article_FlexibleContent_Video) {

  const [isOpen, setIsOpen] = useState(false)

  if (typeof window === "undefined" || !video) return null

  return (
    <Section {...section}>
      <div className="px-0 lg:w-[140%] lg:ml-[-40%] flex flex-col">
        <div className="relative cursor-pointer group" onClick={() => setIsOpen(true)}>
          <Image image={image} />
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-full bg-white text-dark-purple flex items-center justify-center z-10 w-28 h-28">
            <div className="absolute inset-0 w-28 h-28 bg-white rounded-full transition-transform duration-300 ease-in-out group-hover:scale-110" />
            <div className="w-7 fw-icon ml-1 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
              <Icon type={IconType.Play} />
            </div>
          </div>
        </div>

        {caption && <div className="pt-6 opacity-50">{caption}</div>}
      </div>

      <ModalVideo
        channel="custom"
        autoplay={1}
        isOpen={isOpen}
        url={video?.localFile?.publicURL}
        onClose={() => setIsOpen(false)}
      /> 
    </Section>
  );
}
