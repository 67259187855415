import React from "react";
import { Section } from "~/components/elements/Section";
import {
  WpPost_Article_FlexibleContent_Image,
} from "src/_generated/types";
import ImageInner from "~/components/elements/Image";

export default function Image({
  image,
  caption,
  section,
}: WpPost_Article_FlexibleContent_Image) {

  return (
    <Section {...section} >
      <div className="px-0 lg:w-[140%] lg:ml-[-40%] flex flex-col">
        <ImageInner image={image} />
        {caption && <div className="pt-6 opacity-50">{caption}</div>}
      </div>
    </Section>
  );
}
