import { Link } from "gatsby";

import React from "react";

import useCategories from "~/hooks/useCategories";

import {
  BodyCopy,
  BodyCopySizeOption,
} from "~/components/elements/typography/BodyCopy";
import {
  Heading,
  HeadingSizeOption,
} from "~/components/elements/typography/Heading";
import { Section } from "~/components/elements/Section";
import { Button, ButtonType } from "~/components/elements/buttons/Button";
import { Articles } from "~/components/flexible/Resources";
import { WpPage_Page_FlexibleContent_FeaturedResources } from "~/_generated/types";
import { Icon, IconType } from "~/components/elements/Icon";

export default function FeaturedResources({
  heading,
  content,
  resources,
  section,
  quicklinks,
}: WpPage_Page_FlexibleContent_FeaturedResources) {
  FeaturedResources;

  const { nodes } = useCategories();
  const categories = nodes;

  quicklinks = false;

  return (
    <Section {...section}>
      <div className="container">
        <div className="max-w-[520px]">
          <Heading Type="h3" size={HeadingSizeOption.h3} text={heading} />

          <div className="mt-[12px]">
            <BodyCopy size={BodyCopySizeOption.Large} text={content} />
          </div>
        </div>

        <Articles posts={resources} />

        <div className="mt-5 md:mt-[60px]">
          <div className="w-full bg-purple-2 h-[1px]"></div>
        </div>

        {quicklinks && categories && (
          <div className=" mt-5 md:mt-[70px]">
            <QuickLinks quicklinks={quicklinks} categories={categories} />
          </div>
        )}
      </div>
    </Section>
  );
}

export const QuickLinks = ({ quicklinks, categories }) => {
  return (
    <div className="w-full bg-purple-2 rounded-[10px] ">
      <div className="md:flex justify-between pb-3 pt-[0.55rem]  md:pt-[37px] md:px-[42px] md:py-[37px] items-center text-center md:text-start md:space-y-0">
        <div className="hidden md:block text-[26px] font-heading leading-[33px]  ">
          {quicklinks.heading}
        </div>
        <div className="hidden md:flex flex-wrap items-center justify-center space-x-[10px] lg:space-x-[48px]">
          {categories?.map((category, i) => (
            <Link
              key={`ql${i}`}
              to={category.uri}
              className="transition-colors duration-200 ease-in-out hover:text-green-2"
            >
              <div className="flex items-center space-x-2">
                <div
                  className={`w-3 fw-icon ${
                    category?.category?.icon === "video" && "!w-4"
                  }`}
                >
                  <Icon type={category?.category?.icon} />
                </div>
                <span className="ml-[6px] text-[18px] leading-4 block">
                  {category.name}
                </span>
              </div>
            </Link>
          ))}
        </div>
        <Button
          type={ButtonType.Text}
          link={quicklinks.link}
          icon={IconType.ArrowRightDown}
        />
      </div>
    </div>
  );
};
