import React, { useEffect, useLayoutEffect, useRef, useState } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import { A11y } from "swiper";

import "swiper/css";

import {
  BodyCopy,
  BodyCopySizeOption,
} from "~/components/elements/typography/BodyCopy";
import {
  Heading,
  HeadingSizeOption,
} from "~/components/elements/typography/Heading";
import Image from "~/components/elements/Image";
import { Section } from "~/components/elements/Section";

import { WpPage_Page_FlexibleContent_TabbedSliderAlt } from "~/_generated/types";
import { ProgressTab } from "./TabbedSlider";
import { AnimatePresence, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

export default function TabbedSliderAlt({
  heading,
  content,
  slides,
  section,
}: WpPage_Page_FlexibleContent_TabbedSliderAlt) {
  const [active, setActive] = useState(0);
  const interval = useRef(null);

  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });

  useEffect(() => {
    if (inView) {
      interval.current = setInterval(() => {
        setActive((i) => (i + 1) % slides.length);
      }, 25000);
    }

    setActive(0);

    return () => {
      clearInterval(interval.current);
    };
  }, [interval, inView]);

  const overrideActive = (i) => {
    setActive(i);
    clearInterval(interval.current);

    interval.current = setInterval(() => {
      setActive((i) => (i + 1) % slides.length);
    }, 25000);
  };

  return (
    <Section {...section}>
      <div className="container" ref={ref}>
        <div className="text-center">
          <Heading Type="h3" size={HeadingSizeOption.h3} text={heading} />
          <div className="mt-[30px]">
            <BodyCopy size={BodyCopySizeOption.Default} text={content} />
          </div>
        </div>
        <div className="mt-[81px]">
          <div className="flex ">
            {slides?.map((slide, i) => (
              <div
                key={`altProgTab${i}`}
                className={`${
                  slides?.length === 4
                    ? " w-1/4"
                    : slides?.length === 3 && "w-1/3"
                } max-w-[227px] `}
              >
                <ProgressTab
                  onClick={() => overrideActive(i)}
                  isActive={active === i}
                  heading={slide?.label}
                  background="white"
                  inView={inView}
                  duration={25}
                />
              </div>
            ))}
          </div>

          <Slides slides={slides} active={active} />
        </div>
      </div>
    </Section>
  );
}

export const Slides = ({ slides, active }) => {
  const [swiper, setSwiper] = useState(null);

  const slideTo = (index) => swiper?.slideTo(index);

  useLayoutEffect(() => {
    slideTo(active);
  }, [active]);

  return (
    <div className="mt-[47px] lg:flex lg:space-x-[40px] justify-between items-start">
      <div className="w-full lg:w-7/12 ">
        <Swiper
          onSwiper={setSwiper}
          modules={[A11y]}
          className={`w-full rounded-[10px]`}
          slidesPerView={1}
          draggable={false}
        >
          {slides.map((slide, i) => (
            <SwiperSlide
              key={`tabbedswiper${i}`}
              style={{ height: "auto" }}
              className="rounded-[10px]"
            >
              <div className="rounded-[10px]">
                <Image
                  image={slide?.image}
                  className="w-full h-full lg:max-w-[694px] rounded-[10px]"
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      <div className="w-full mt-12 lg:w-5/12 lg:mt-0 ">
        <AnimatePresence>
          {slides?.map((slide, i) => (
            <>
              {i === active && (
                <motion.div
                  transition={{ duration: 0.5 }}
                  initial={{ y: 10, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  exit={{ y: 10, opacity: 0 }}
                >
                  <div className="lg:max-w-[443px] ml-auto">
                    <div className="text-purple-2">
                      <Heading
                        Type="h4"
                        size={HeadingSizeOption.h4}
                        text={slide?.label}
                      />
                    </div>
                    <div className="mt-[6px]">
                      <Heading
                        Type="h5"
                        size={HeadingSizeOption.h5}
                        text={slide?.heading}
                      />
                    </div>
                    <div className="mt-[23px]">
                      <BodyCopy
                        size={BodyCopySizeOption.Default}
                        text={slide?.content}
                      />
                    </div>
                  </div>
                </motion.div>
              )}
            </>
          ))}
        </AnimatePresence>
      </div>
    </div>
  );
};
