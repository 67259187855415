import React from "react";
import { Section } from "~/components/elements/Section";
import {
  WpPost_Article_FlexibleContent_Blockquote,
} from "src/_generated/types";
import { Heading, HeadingSizeOption } from "~/components/elements/typography/Heading";

export default function Blockquote({
  quote,
  section,
}: WpPost_Article_FlexibleContent_Blockquote) {

  return (
    <Section {...section}>
      <div className="border-l border-l-purple pl-6 md:pl-10">
        <Heading type="h4" size={HeadingSizeOption.h4} text={quote} />
      </div>
    </Section>
  );
}
