import React, { useEffect, useState } from "react";

import {
  BodyCopy,
  BodyCopySizeOption,
} from "~/components/elements/typography/BodyCopy";
import {
  Heading,
  HeadingSizeOption,
} from "~/components/elements/typography/Heading";
import Image from "~/components/elements/Image";
import { Section } from "~/components/elements/Section";
import ButtonGroup from "~/components/elements/buttons/ButtonGroup";
import Border, { BorderSizeOption } from "~/components/elements/Border";
import Note from "~/components/elements/Note";
import { WpPage_Page_FlexibleContent_TwoColumn } from "~/_generated/types";
import GridLines from "~/components/elements/GridLines";
import LottieAnimation from "~/components/elements/LottieAnimation";
import { useInView } from "react-intersection-observer";

export default function TwoColumn({
  layout,
  icon,
  number,
  heading,
  content,
  content2,
  links,
  image,
  section,
  reverse,
  imageWidth,
  textBorder,
  imageBleed,
  note,
  addNote,
  mediaType,
  animation,
  absoluteImage,
  absoluteImg,
}: WpPage_Page_FlexibleContent_TwoColumn) {
  let imageWidthClass = "md:w-1/2";
  let textWidthClass = "max-w-[450px]";

  switch (imageWidth) {
    case "half":
      imageWidthClass = "md:w-1/2";
      textWidthClass = "lg:max-w-[450px]";
      break;
    case "one-third":
      imageWidthClass = "md:w-1/2 lg:w-1/3";
      textWidthClass = "max-w-[620px]";
      break;
    case "two-third":
      imageWidthClass = "md:w-1/2 lg:w-2/3";
      break;
  }

  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });

  return (
    <Section {...section}>
      <div className="overflow-hidden">
        <div className="tc-grid-columns">
          <GridLines colour="10" />
        </div>
        <div
          className={`container  ${
            section.borderTop === "curved" && " md:pt-0"
          }`}
        >
          <div
            className={`flex flex-container ${
              imageBleed && !reverse ? "flex-col" : "flex-col-reverse"
            }  justify-between ${
              reverse ? "md:flex-row-reverse" : "md:flex-row"
            }  ${layout === "2" ? "items-stretch" : "items-center"}`}
          >
            <div
              className={`tc-content flex mx-auto ${
                reverse ? "md:pl-5 lg:pl-12" : "md:pr-5 lg:pr-12"
              } ${
                layout === "2" &&
                "bg-purple-5 w-full md:w-1/2 px-6 md:px-12 py-8 lg:px-24 lg:py-16 flex-col justify-center"
              }`}
            >
              <Border textBorder={textBorder} size={BorderSizeOption.Large}>
                <div>
                  <div className={`${textWidthClass}  text-width-class`}>
                    <div className="max-w-[1092px] space-y-[26px]">
                      {icon && (
                        <div className="w-[73px] h-[73px]">
                          <Image image={icon} />
                        </div>
                      )}

                      {number && (
                        <div className="text-purple mb-[70px]">
                          <Heading
                            Type="h5"
                            size={HeadingSizeOption.h5}
                            text={number}
                          />
                        </div>
                      )}

                      <div className="heading">
                        <Heading
                          Type="h3"
                          size={HeadingSizeOption.h3}
                          text={heading}
                        />
                      </div>
                      <div className="max-w-[770px] body-copy">
                        {layout === "3" ? (
                          <>
                            {content2 && (
                              <div
                                className="prose text-[23px] tracking-[-0.02em] content-2"
                                dangerouslySetInnerHTML={{ __html: content2 }}
                              />
                            )}
                          </>
                        ) : (
                          <>
                            {content && (
                              <BodyCopy
                                size={BodyCopySizeOption.Large}
                                text={content}
                              />
                            )}
                          </>
                        )}
                      </div>
                    </div>
                    {links && (
                      <div className="mt-[27px]">
                        <ButtonGroup links={links} />
                      </div>
                    )}

                    {addNote === "Yes" && note && (
                      <div className="mt-10 md:mt-[50px]">
                        <Note
                          {...note}
                          largeCopy={layout === "3"}
                          classname={layout === "3" && "!max-w-[443px]"}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </Border>
            </div>
            <div
              className={`tc-image w-full ${
                layout !== "2" && "mb-12"
              } md:mb-[50px] lg:mb-0 ${imageWidthClass} relative`}
            >
              {absoluteImage && (
                <div className="w-full h-full absolute-image-container">
                  <div className="absolute-img w-[110%] lg:w-[108%] left-[-10%] lg:left-[-25%] xl:left-[-22.5%]">
                    <Image image={absoluteImg} className="w-full h-full" />
                  </div>
                </div>
              )}
              <div
                ref={ref}
                className={`two-col-img relative
                  ${
                    imageBleed && reverse
                      ? "w-[110%] lg:w-[108%] left-[-10%] lg:left-[-25%] xl:left-[-22.5%]"
                      : imageBleed &&
                        !reverse &&
                        "w-[120%] lg:left-[10%] xl:left-[6.5%]"
                  }`}
              >
                {mediaType === "animation" ? (
                  <>
                    {animation && (
                      <LottieAnimation
                        loop={false}
                        play={inView}
                        lazyData={animation}
                      />
                    )}
                  </>
                ) : (
                  <>{image && <Image image={image} />}</>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
}
