import React, { useEffect, useLayoutEffect, useRef, useState } from "react";

import {
  BodyCopy,
  BodyCopySizeOption,
} from "~/components/elements/typography/BodyCopy";
import {
  Heading,
  HeadingSizeOption,
} from "~/components/elements/typography/Heading";
import Image from "~/components/elements/Image";
import { Section, SectionProps } from "~/components/elements/Section";

import { WpPage_Page_FlexibleContent_ContentSlider } from "~/_generated/types";
import { motion, useElementScroll, useViewportScroll } from "framer-motion";

export default function ContentSlider({
  heading,
  content,
  slides,
  section,
  nextLayout,
}: WpPage_Page_FlexibleContent_ContentSlider) {
  const [containerHeight, setContainerHeight] = useState("");

  const ref = useRef();
  const nextSectionRef = useRef();
  const length = slides.length;

  const [progress, setProgress] = useState(0);
  const [scrollY, setScrollY] = useState(0);
  const [nextSectionHeight, setNextSectionHeight] = useState(0);

  const maxProgress = (1 / length) * (length - 1);

  useEffect(() => {
    const onScroll = (e) => {
      setScrollY(e.target.documentElement.scrollTop);
    };
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  useLayoutEffect(() => {
    const nextSectionHeight = nextSectionRef.current.offsetHeight;

    const relevantHeight = ref.current.offsetHeight - nextSectionHeight;
    setNextSectionHeight(nextSectionHeight);
    const sectionY = ref.current.offsetTop;
    const sectionOverflow = scrollY - sectionY;

    let _progress = sectionOverflow / relevantHeight;

    if (_progress < 0) _progress = 0;
    if (_progress > maxProgress) _progress = maxProgress;

    setProgress(_progress);
  }, [length, ref, containerHeight, scrollY]);

  return (
    <>
      <div
        data-layout="ContentSliderWrapper"
        className="relative hidden content-slider md:block"
        style={{ height: `calc(${length * 100}vh + ${nextSectionHeight}px` }}
        ref={ref}
      >
        <div className="sticky top-0">
          <Section {...section} classname="static overflow-hidden">
            <div className="container">
              <div className="space-y-24 border-padding-left-large content-slider-border-fix">
                <div className="space-y-3 max-w-[580px]">
                  <Heading
                    type="h2"
                    size={HeadingSizeOption.h2}
                    text={heading}
                  />
                  <BodyCopy size={BodyCopySizeOption.Large} text={content} />
                </div>
                <motion.div
                  className="flex content-slider-pane"
                  style={{ translateX: -(progress * (100 * length)) + "%" }}
                >
                  {slides.map((slide, i) => (
                    <div
                      className={`w-full transform flex-shrink-0 pr-12 last:mr-0 flex flex-col h-auto`}
                      key={`contentswiper${i}`}
                    >
                      <div className="space-y-12">
                        <div className="rounded-[4px]">
                          <Image image={slide.image} loading="eager" />
                        </div>
                        <div className="max-w-[600px] space-y-5">
                          <Heading
                            type="h4"
                            size={HeadingSizeOption.h4}
                            text={slide.heading}
                          />
                          <BodyCopy text={slide.content} />
                        </div>
                      </div>
                    </div>
                  ))}
                </motion.div>
              </div>
            </div>
          </Section>
          <div ref={nextSectionRef}>{nextLayout()}</div>
        </div>
      </div>

      <div className="container block md:hidden pt-[70px]">
        <div className="space-y-3">
          <Heading type="h2" size={HeadingSizeOption.h2} text={heading} />
          <BodyCopy size={BodyCopySizeOption.Large} text={content} />
        </div>
        <div className="mt-12">
          {slides.map((slide, i) => (
            <div
              className={`w-full  flex flex-col h-auto ${
                i !== slides?.length - 1 && "mb-8"
              } `}
              key={`mobileSlide${i}`}
            >
              <div className="space-y-6">
                <div className="rounded-[4px]">
                  <Image image={slide.image} loading="eager" />
                </div>
                <div className="max-w-[600px] space-y-3">
                  <Heading
                    type="h4"
                    size={HeadingSizeOption.h4}
                    text={slide.heading}
                  />
                  <BodyCopy text={slide.content} />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
