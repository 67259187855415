import React from 'react'
import { Icon } from "~/components/elements/Icon";

export default function SocialLinks({ socialLinks }) {
  return (
    <div className="flex">
      {socialLinks.map((socialLink, i) => (
        <a key={i} href={socialLink.link.url} target="_blank" className="block w-3.5 h-auto hover:text-green-2 transition-colors duration-200 ease-in-out">
          <Icon type={socialLink.icon} />
        </a>
      ))}
    </div>
  )
}
