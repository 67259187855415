import React, { useEffect, useLayoutEffect, useRef, useState } from "react";

import {
  BodyCopy,
  BodyCopySizeOption,
} from "~/components/elements/typography/BodyCopy";
import {
  Heading,
  HeadingSizeOption,
} from "~/components/elements/typography/Heading";
import Image from "~/components/elements/Image";
import { Section } from "~/components/elements/Section";
import { Button, ButtonType } from "~/components/elements/buttons/Button";

import {
  WpPage_Page_FlexibleContent_BlockLinks,
  WpPage_Page_FlexibleContent_BlockLinks_Blocks,
} from "~/_generated/types";
import { IconType } from "~/components/elements/Icon";
import { Link } from "gatsby";

export default function BlockLinks({
  blocks,
  heading,
  section,
  layout,
}: WpPage_Page_FlexibleContent_BlockLinks) {
  return (
    <Section {...section}>
      <div className="container space-y-12 md:space-y-28">
        {heading && (
          <div className="text-center">
            <Heading size={HeadingSizeOption.h2} Type="h2" text={heading} />
          </div>
        )}
        {layout === "1" ? (
          <List blocks={blocks} />
        ) : layout === "2" ? (
          <TwoColumns blocks={blocks} />
        ) : null}
      </div>
    </Section>
  );
}

export const TwoColumns = ({
  blocks,
}: WpPage_Page_FlexibleContent_BlockLinks_Blocks) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-y-12 md:gap-y-0 md:gap-x-5">
      {blocks?.map((block, i) => (
        <ColourBlockAlt key={`blockLink${i}`} {...block} />
      ))}
    </div>
  );
};

export const List = ({
  blocks,
}: WpPage_Page_FlexibleContent_BlockLinks_Blocks) => {

  return (
    <div className={`space-y-7 md:space-y-10`}>
      {blocks?.map((block, i) => (
        <ColourBlock key={`blockLink${i}`} {...block}  />
      ))}
    </div>
  );
};

export const ColourBlock = ({
  heading,
  content,
  image,
  backgroundColor,
  page
}: WpPage_Page_FlexibleContent_BlockLinks_Blocks) => {
  let bgColourClass = "";

  const block = useRef(null);

  switch (backgroundColor) {
    case "green":
      bgColourClass = "bg-green-6";
      break;
    case "orange":
      bgColourClass = "bg-orange-4";
      break;
    case "blue":
      bgColourClass = "bg-[#EDF6FF]";
      break;
  }

  return (
    <div className="h-auto top-40 md:sticky pointer-events-none">
      <div className="md:flex pointer-events-auto"  ref={block}>
        <div className={`w-full md:w-1/2 ${bgColourClass} `}>
          <div className="px-6 py-[33px] md:py-6 lg:px-[71px] lg:py-[71px] h-full flex flex-col justify-between">
            <div>
              <div className="mb-[29px]">
                <Heading size={HeadingSizeOption.h3} Type="h3" text={heading} />
              </div>
              <div className="opacity-60">
                <BodyCopy size={BodyCopySizeOption.Large} text={content} />
              </div>
            </div>
            <div className="mt-3 md:mt-[17px]">
              <Button
                type={ButtonType.Text}
                icon={IconType.ArrowRightDown}
                link={{ title: "Learn more", url: page[0]?.uri }}
              />
            </div>
          </div>
        </div>

        <div className="w-full md:w-1/2">
          <Image image={image} />
        </div>
      </div>
    </div>
  );
};

export const ColourBlockAlt = ({
  heading,
  content,
  image,
  page,
}: WpPage_Page_FlexibleContent_BlockLinks_Blocks) => {
  return (
    <Link to={page[0]?.uri} className="space-y-10 group">
      <div className="w-full aspect-w-6 aspect-h-5 rounded-[10px] overflow-hidden">
        <Image image={image} objectFit="cover" className="transform group-hover:scale-105 transition-transform duration-700 ease-in-out !absolute" />
      </div>
      <div className={`w-full`}>
        <div className="space-y-6 md:space-y-12">
          <div className="space-y-6">
            <Heading size={HeadingSizeOption.h4} Type="h4" text={heading} />
            <div className="text-white text-opacity-70 max-w-[523px]">
              <BodyCopy size={BodyCopySizeOption.Large} text={content} />
            </div>
          </div>
          <div className=" mt-[17px]">
            <Button
              type={ButtonType.Text}
              icon={IconType.ArrowRightDown}
              link={{ title: "Learn more", url: page[0]?.uri }}
            />
          </div>
        </div>
      </div>
    </Link>
  );
};
