import React, { useState } from "react";

import {
  BodyCopy,
  BodyCopySizeOption,
} from "~/components/elements/typography/BodyCopy";
import {
  Heading,
  HeadingSizeOption,
} from "~/components/elements/typography/Heading";
import Image from "~/components/elements/Image";
import { Section } from "~/components/elements/Section";
import SocialLinks from "~/components/elements/SocialLinks";
import Switcher from "~/components/elements/Switcher";

import { WpPage_Page_FlexibleContent_Team } from "~/_generated/types";
import { AnimatePresence, motion, LayoutGroup } from "framer-motion";
import { Icon } from "../elements/Icon";

export default function Team({
  section,
  heading,
  members,
  switcher,
  content,
}: WpPage_Page_FlexibleContent_Team) {
  const [active, setActive] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [activeMember, setActiveMember] = useState(null);

  const modalHandler = (i) => {
    setShowModal(true);
    setActiveMember(i);
  };

  return (
    <Section {...section}>
      <LayoutGroup>
        <div className="container space-y-8 md:space-y-20">
          <div className="grid gap-y-6 lg:gap-y-0 lg:grid-cols-2 lg:items-end">
            <div className="space-y-3 border-l border-l-purple border-padding-left-small ">
              <Heading type="h3" size={HeadingSizeOption.h3} text={heading} />
              <BodyCopy size={BodyCopySizeOption.Large} text={content} />
            </div>
            <div className="flex justify-end lg:mb-5">
              <Switcher
                label1={switcher.switch1}
                label2={switcher.switch2}
                light={false}
                active={active}
                setActive={setActive}
                labelOpacity
              />
            </div>
          </div>
          <TeamMembers
            members={members}
            active={active}
            modalHandler={modalHandler}
          />
        </div>
        <AnimatePresence>
          {showModal && (
            <MemberModal
              setShowModal={setShowModal}
              members={members}
              active={active}
              activeMember={activeMember}
            />
          )}
        </AnimatePresence>
      </LayoutGroup>
    </Section>
  );
}

export const MemberModal = ({ setShowModal, members, active, activeMember }) => {

  let active_members = [];

  if (active === true) {
    active_members = members[1]?.member
  } else {
    active_members = members[0]?.member
  }

  const member = active_members[activeMember];

  return (
    <motion.div
      style={{ willChange: "opacity" }}
      className="fixed  z-[200] h-screen inset-0 team-modal  "
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      <div
        className="w-full h-full !bg-purple-2 !bg-opacity-[0.93]  hover:!bg-purple-2 hover:!bg-opacity-[0.93] "
        onClick={() => setShowModal(false)}
      >
        {/* <div className="fixed inset-0 z-[110]"> */}
        <div className="absolute inset-0 flex items-center justify-center w-full h-full px-4 ">
          <motion.div
            initial={{ background: "rgba(0, 0, 0, 0)" }}
            animate={{ background: "rgba(255, 255, 255, 1)" }}
            // transition={{ duration: 1, delay: 0.5 }}
            className="overflow-scroll rounded-[15px] text-dark-purple max-h-[612px] max-w-[996px] w-full mx-auto relative  z-[210]"
            // initial={{ y: 20 }}
            // animate={{ y: 0 }}
            // transition={{ duration: 0.4 }}
          >
            <div className="absolute top-[14px] right-[14px] md:top-[30px] md:right-[25px]  ">
              <motion.svg
                initial="rest"
                whileHover="hover"
                animate="rest"
                className="cursor-pointer h-[32px] w-[32px] md:h-[48px] md:w-[48px] "
                onClick={() => setShowModal(false)}
                width="48"
                height="48"
                viewBox="0 0 48 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <motion.circle
                  variants={{
                    rest: {
                      strokeOpacity: 0.18,
                    },
                    hover: {
                      strokeOpacity: 1,
                    },
                  }}
                  cx="24"
                  cy="24"
                  r="23.5"
                  stroke="black"
                />
                <path d="M18 18L31 31" stroke="black" />
                <path d="M18 31L31 18" stroke="black" />
              </motion.svg>
            </div>
            <div className="flex flex-wrap md:flex-nowrap md:space-x-[20px] lg:space-x-[63px] px-6 pt-16 pb-12 md:pl-[22px] md:pr-[32px] md:pt-[27px] md:pb-[62px] lg:pl-[57px] lg:py-[62px] lg:pr-[77px]">
              <div className="w-full md:mt-12 lg:mt-0 max-w-[320px]">
                <motion.div layoutId={`teamMember${activeMember}`}>
                  <Image
                    image={member?.teamMember?.image}
                    className="w-full h-full max-w-[320px]"
                  />
                </motion.div>
              </div>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.6, delay: 0.3 }}
                className="mt-8 md:mt-12 lg:mt-0 overflow-hidden"
              >
                <motion.h3
                  initial={{ y: 200 }}
                  animate={{ y: 0 }}
                  transition={{ duration: 0.6, delay: 0.6, ease: "easeOut" }}
                  className="text-h3 text-purple-2"
                >
                  {member?.teamMember?.name}
                </motion.h3>
                <div className="mt-[10px]">
                  <motion.div
                    initial={{ x: -160, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{ duration: 0.5, delay: 1, ease: "easeOut" }}
                  >
                    <BodyCopy
                      size={BodyCopySizeOption.Large}
                      text={member?.teamMember?.jobTitle}
                    />
                  </motion.div>
                </div>
                <motion.div
                  className="mt-[17px]"
                  initial={{ opacity: 0 }}
                  animate={{
                    opacity: 1,
                  }}
                  transition={{ duration: 0.3, delay: 1.2 }}
                >
                  <div
                    className="prose text-[14px]"
                    dangerouslySetInnerHTML={{
                      __html: member?.teamMember?.description,
                    }}
                  />
                </motion.div>
                <AnimatePresence>
                  {member?.teamMember?.socialLinks && (
                    <motion.div
                      className="mt-[20px] flex items-center space-x-[6px] pointer-events-auto"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{ duration: 0.3, delay: 1.2 }}
                    >
                      {member?.teamMember?.socialLinks.map(
                        (socialLink, i) => (
                          <a
                            key={`socialLink${i}`}
                            href={socialLink?.link?.url}
                            target="_blank"
                            className="bg-purple-2 rounded tracking-[-0.02em] transition-colors duration-200 text-white flex items-center justify-between space-x-[20px] py-[12px] px-[16px] hover:!bg-black hover:!text-white"
                          >
                            <div>{socialLink?.link?.title}</div>
                            <Icon type={socialLink?.icon} />
                          </a>
                        )
                      )}
                    </motion.div>
                  )}
                </AnimatePresence>
              </motion.div>
            </div>
          </motion.div>
        </div>
        {/* </div> */}
      </div>
    </motion.div>
  );
};

export const TeamMembers = ({ members, active, modalHandler }) => {
  let switcher;
  let active_members = [];

  if (active === true) {
    switcher = "switch_2";
    active_members = members[1]?.member
  } else {
    switcher = "switch_1";
    active_members = members[0]?.member
  }


  return (
    <>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-x-6 gap-y-12">
        <AnimatePresence>
          {active_members.map((member, i) => (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3, ease: "easeInOut" }}
              key={`teamMember${i}`}
              className="space-y-5 cursor-pointer group"
              onClick={() => modalHandler(i)}
            >
              <motion.div
                layoutId={`teamMember${i}`}
              >
                <div className="aspect-h-5 aspect-w-4">
                  <Image
                    image={member?.teamMember?.image}
                    className="!absolute"
                    objectFit="cover"
                  />
                </div>
              </motion.div>
              <div className="pl-5 space-y-4 border-l border-l-purple">
                <div className="space-y-2">
                  <div className="text-purple group-hover:text-white transition-color duration-500">
                    <Heading
                      type="h5"
                      size={HeadingSizeOption.h5}
                      text={member?.teamMember?.name}
                    />
                  </div>
                  <BodyCopy
                    size={BodyCopySizeOption.Small}
                    text={member?.teamMember?.jobTitle}
                  />
                </div>
                {member?.teamMember?.socialLinks && (
                  <SocialLinks
                    socialLinks={member?.teamMember?.socialLinks}
                  />
                )}
              </div>
            </motion.div>
          ))}
        </AnimatePresence>
      </div>
    </>
  );
};
